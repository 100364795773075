import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route } from 'react-router-dom';
import Cookie from 'js-cookie';

import './App.css';

import Authenticated from 'authenticated/Index';
import Login from 'public/Login';

import Loader from 'shared/Loader';

import { AuthContext } from 'src/AuthContext';

import Api from 'utils/Api';

class Harbor extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      user: null,
      loading: true
    };
  }

  componentDidMount () {
    const cookie_auth = Cookie.get('dxp') ? JSON.parse(Cookie.get('dxp')) : {};

    if (cookie_auth.access_token) {
      Api.loadUser('me')
        .then(response => {
          this.setState({
            user: response.data,
            loading: false
          });
        });
    } else {
      this.setState({
        user: null,
        loading: false
      });
    }
  }

  render () {
    return this.state.loading ? <Loader /> : (
      <AuthContext.Provider value={{ user: this.state.user }}>
        {this.state.user ? <Authenticated {...this.props} /> : <Login {...this.props} />}
      </AuthContext.Provider>
    );
  }
}

function logPageView () {
//   ReactGA.set({ page: window.location.pathname + window.location.search });
//   ReactGA.pageview(window.location.pathname + window.location.search);
}


ReactDOM.render((
  <Router onUpdate={logPageView}>
    <Route component={Harbor} path='/' />
  </Router>
), document.getElementById('harbor'));
