import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from 'constants/Styles';

const Checklist = (props) => {
  const id = Math.round(Math.random() * 1000000);
  const columnCount = props.columnCount || 1;
  const styles = {
    checklist: {
      margin: '10px 8px 0px 8px'
    },
    columns: {
      columnCount,
      columnFill: columnCount > 1 ? 'auto' : 'none',
      height: columnCount > 1 ? 450 : 'auto'
    },
    option: {
      display: 'flex',
      padding: '12px 0px 10px',
      marginRight: 20,
      borderBottom: '1px solid #e5e5e5',
      cursor: props.readOnly ? 'default' : 'pointer'
    },
    checkbox: {
      fontSize: 20,
      marginRight: 8
    },
    label: {
      fontSize: 14,
      fontWeight: 700,
      marginTop: 2
    },
    description: {
      fontSize: 13,
      marginTop: 6
    }
  };

  const all_selected = props.selected.length === props.options.length;

  const _handleOptionClick = (option, selected) => {
    if (props.onChange) {
      const selected_options = props.selected.filter(o => o !== option.value);

      if (selected) {
        selected_options.push(option.value);
      }

      props.onChange(selected_options);
    }
  };

  const _handleSelectAll = () => {
    if (all_selected) {
      props.onChange([]);
    } else {
      props.onChange(props.options.map(o => o.value));
    }
  };

  return (
    <div style={styles.checklist}>
      {props.showSelectAll && !props.readOnly ? (
        <div onClick={_handleSelectAll} style={styles.option}>
          <i className={`mdi ${all_selected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}`} style={Object.assign({}, styles.checkbox, { color: all_selected ? Colors.GREEN.hex : '#777' })} />
          <div style={styles.label}>Select All</div>
        </div>
      ) : null}

      <div style={styles.columns}>
        {props.options.map((o, i) => {
          const selected = props.selected.includes(o.value);

          return (
            <div key={`${id}_${i}`} onClick={props.readOnly ? null : _handleOptionClick.bind(null, o, !selected)} style={styles.option}>
              <i className={`mdi ${selected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}`} style={Object.assign({}, styles.checkbox, { color: selected ? Colors.GREEN.hex : '#777' })} />
              <div>
                <div style={styles.label}>{o.label}</div>
                <div style={styles.description}>{o.description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Checklist.propTypes = {
  columnCount: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    description: PropTypes.string
  })).isRequired,
  readOnly: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  showSelectAll: PropTypes.bool
};

export default Checklist;

