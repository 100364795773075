module.exports = {
  PRODUCT_TYPE_DETAILS_BY_PRODUCT_TYPE: {
    'Term': [
      '1 Year Term',
      '5 Year Term',
      '10 Year Term',
      '11 Year Term',
      '12 Year Term',
      '13 Year Term',
      '14 Year Term',
      '15 Year Term',
      '16 Year Term',
      '17 Year Term',
      '18 Year Term',
      '19 Year Term',
      '20 Year Term',
      '21 Year Term',
      '22 Year Term',
      '23 Year Term',
      '24 Year Term',
      '25 Year Term',
      '26 Year Term',
      '27 Year Term',
      '28 Year Term',
      '29 Year Term',
      '30 Year Term',
      '31 Year Term',
      '32 Year Term',
      '33 Year Term',
      '34 Year Term',
      '35 Year Term',
      '40 Year Term'
    ],
    'Term ROP': [
      '1 Year Term ROP',
      '5 Year Term ROP',
      '10 Year Term ROP',
      '12 Year Term ROP',
      '15 Year Term ROP',
      '16 Year Term ROP',
      '17 Year Term ROP',
      '18 Year Term ROP',
      '19 Year Term ROP',
      '20 Year Term ROP',
      '21 Year Term ROP',
      '22 Year Term ROP',
      '23 Year Term ROP',
      '24 Year Term ROP',
      '25 Year Term ROP',
      '26 Year Term ROP',
      '27 Year Term ROP',
      '28 Year Term ROP',
      '29 Year Term ROP',
      '30 Year Term ROP',
      '31 Year Term ROP',
      '32 Year Term ROP',
      '33 Year Term ROP',
      '34 Year Term ROP',
      '35 Year Term ROP',
      '40 Year Term ROP'
    ],
    'UL': [
      'To age 65',
      'To age 65 (ROP)',
      'To age 70',
      'To age 70 (ROP)',
      'To age 75',
      'To age 75 (ROP)',
      'To age 80',
      'To age 85',
      'To age 90',
      'To age 95',
      'To age 100',
      'To age 105',
      'To age 110',
      'To age 121 (No Lapse U/L)',
      'To age 121 (Pay to 65)',
      'To age 121 (Pay to 100)',
      'To age 121 (20 Pay)',
      'To age 121 (10 Pay)',
      'To age 121 (Single Pay)'
    ],
    'Whole Life': ['Whole Life'],
    'Final Expense': ['Final Expense']
  }
};
