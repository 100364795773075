import React from 'react';
import PropTypes from 'prop-types';

import Alert from 'shared/Alert';
import Button from 'shared/Button';
import Checklist from 'shared/Checklist';
import Drawer from 'shared/Drawer';
import Field from 'shared/Field';
import Input from 'shared/Input';

import Api from 'utils/Api';

import { _validateEmail } from 'constants/Form';

import { AuthContext } from 'src/AuthContext';


class UserForm extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    show: PropTypes.bool,
    user: PropTypes.object
  }

  constructor (props) {
    super(props);

    const user = props.user || {};

    this.state = {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      email: user.email || '',
      permissions: user.permissions || [],
      password: '',
      password_confirmation: '',
      errors: [],
      save_errors: []
    };
  }


  _handleInputChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  }

  _handleChecklistChange = (name, selected) => {
    this.setState({
      [name]: selected
    });
  }

  _save = () => {
    const errors = [];

    if (!this.state.first_name || this.state.first_name.length < 3) {
      errors.push('first_name');
    }

    if (!this.state.last_name || this.state.last_name.length < 3) {
      errors.push('last_name');
    }

    if (!_validateEmail(this.state.email)) {
      errors.push('email');
    }

    if (!this.props.user || this.state.password.length) {
      if (!this.state.password || this.state.password.length < 6) {
        errors.push('password');
      }

      if (this.state.password !== this.state.password_confirmation) {
        errors.push('password_confirmation');
      }
    }

    if (errors.length) {
      this.setState({ errors });
    } else if (this.props.user) {
      Api.updateCarrierAdmin(this.props.user.id, this.state)
        .then(response => {
          this.props.onSave(response.data);
        });
    } else {
      Api.createCarrierAdmin(this.state)
        .then(response => {
          this.props.onSave(response.data);
        }).catch(({ response }) => {
          this.setState({ save_errors: response.data });
        });
    }
  }

  render () {
    const carrier_product_permissions = this.context.user.carrier_product_permissions;
    const permission_list = [
      { value: 'users', label: 'Users', description: 'Manage user access and information.' },
      { value: 'carrier', label: 'Carrier', description: 'Edit carrier general information.' }
    ];

    if (carrier_product_permissions.includes('form_management')) {
      permission_list.push(
        { value: 'forms', label: 'Forms', description: 'View and edit all Forms.' },
        { value: 'products', label: 'Products', description: 'View and edit all Products.' }
      );
    }

    if (carrier_product_permissions.includes('vendor_management')) {
      permission_list.push(
        { value: 'vendors', label: 'Partners', description: 'View and edit all Partners.' }
      );
    }

    return (
      <Drawer
        footer={(
          <Button fullWidth={true} onClick={this._save}>{this.props.user ? 'Save User' : 'Create User'}</Button>
        )}
        onClose={this.props.onClose}
        show={this.props.show}
        title={this.props.user ? 'Edit User' : 'New User'}
      >
        {this.state.save_errors ? this.state.save_errors.map(e => <Alert type='error'>{e.message.substring(e.message.indexOf(':') + 1)}</Alert>) : null}

        <Field label='First Name'>
          <Input
            invalid={this.state.errors.includes('first_name')}
            onChange={this._handleInputChange.bind(null, 'first_name')}
            required={true}
            value={this.state.first_name}
          />
        </Field>

        <Field label='Last Name'>
          <Input
            invalid={this.state.errors.includes('last_name')}
            onChange={this._handleInputChange.bind(null, 'last_name')}
            required={true}
            value={this.state.last_name}
          />
        </Field>

        <Field label='Email'>
          <Input
            invalid={this.state.errors.includes('email')}
            onChange={this._handleInputChange.bind(null, 'email')}
            readOnly={!!this.props.user}
            required={true}
            value={this.state.email}
          />
        </Field>

        <Field label='Password'>
          <Input
            invalid={this.state.errors.includes('password')}
            onChange={this._handleInputChange.bind(null, 'password')}
            required={!this.props.user}
            type='password'
            value={this.state.password}
          />
        </Field>

        <Field label='Password Confirmation'>
          <Input
            invalid={this.state.errors.includes('password_confirmation')}
            onChange={this._handleInputChange.bind(null, 'password_confirmation')}
            required={!this.props.user}
            type='password'
            value={this.state.password_confirmation}
          />
        </Field>

        <Field
          label='Permissions'
          labelDescription='Select which resources this user can access.'
        >
          <Checklist
            onChange={this._handleChecklistChange.bind(null, 'permissions')}
            options={permission_list}
            selected={this.state.permissions}
          />
        </Field>
      </Drawer>
    );
  }
}


UserForm.contextType = AuthContext;

export default UserForm;
