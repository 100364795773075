import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import _debounce from 'lodash/debounce';

import Button from 'shared/Button';

import List from './List';
import ProductForm from './ProductForm';
import ProductGroupForm from './ProductGroupForm';

import { Colors } from 'constants/Styles';

import Api from 'utils/Api';

class Products extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      search: '',
      loading: true,
      show_product_form: false,
      product: null,
      show_product_group_form: false,
      product_group: null,
      product_groups: [],
      product_group_hover_id: null,
      search_results: []
    };

    this.searchProducts = _debounce(e => {
      const search = e.target.value;

      if (search.length > 2) {
        Api.loadProducts({ search })
          .then(response => {
            this.setState({
              search_results: response.data
            });
          });
      } else {
        this.setState({
          search_results: []
        });
      }
    }, 400);
  }

  componentDidMount () {
    Api.loadProductGroups()
      .then(response => {
        this.setState({
          product_groups: response.data,
          loading: false
        });
      });
  }

  _search = (e) => {
    e.persist();
    this.searchProducts(e);
  }

  _clearSearch = e => {
    this.setState({ search_results: [] });

    e.target.value = '';
  }

  _toggleProductGroupHover = (product_group_hover_id) => {
    this.setState({ product_group_hover_id });
  }

  _toggleProductGroupForm = (show_product_group_form, product_group) => {
    this.setState({ show_product_group_form, product_group });
  }

  _toggleProductForm = (show_product_form, product) => {
    this.setState({ show_product_form, product });
  }

  _handleProductGroupSaved = product_group => {
    const product_groups = this.state.product_groups.filter(pg => pg.id !== product_group.id);

    product_groups.push(product_group);

    this.setState({
      product_groups,
      show_product_group_form: false,
      product_group: null
    });
  }

  _handleProductGroupDeleted = product_group => {
    const product_groups = this.state.product_groups.filter(pg => pg.id !== product_group.id);

    this.setState({
      product_groups,
      show_product_group_form: false,
      product_group: null
    });
  }

  _handleProductSaved = () => {
    // TODO: optimistically update so we don't make additional api calls
    window.location.reload();
  }

  _handleProductDeleted = () => {
    // TODO: optimistically update so we don't make additional api calls
    window.location.reload();
  }

  render () {
    const styles = this.styles();
    const sorted_product_groups = this.state.product_groups.sort((a, b) => {
      return a.group_name.toLowerCase() < b.group_name.toLowerCase() ? -1 : 0;
    });

    return (
      <div className='flex w--100 h--100'>
        <div style={styles.productMenu}>
          <h2>Products</h2>

          <div style={styles.menu}>
            <NavLink
              activeStyle={styles.active}
              exact={true}
              style={styles.menuItem}
              to='/products'
            >
              All
            </NavLink>

            {sorted_product_groups.map(product_group => {
              return (
                <div
                  className='flex flex--space-between flex--align-center'
                  key={product_group.id}
                  onMouseEnter={this._toggleProductGroupHover.bind(null, product_group.id)}
                  onMouseLeave={this._toggleProductGroupHover.bind(null, null)}
                  style={styles.product_group}
                >
                  <NavLink
                    activeStyle={styles.active}
                    key={product_group.id}
                    style={styles.menuItem}
                    to={`/products/${product_group.id}`}
                  >
                    {product_group.group_name}
                  </NavLink>
                  {product_group.id === this.state.product_group_hover_id ? (
                    <i
                      className='mdi mdi-square-edit-outline'
                      onClick={this._toggleProductGroupForm.bind(null, true, product_group)}
                      style={styles.menuItemIcon}
                    />
                  ) : null}
                </div>
              );
            })}

            <Button
              fullWidth={true}
              onClick={this._toggleProductGroupForm.bind(null, true, null)}
              style={{ marginTop: 20 }}
            >
              New Product Group <i className='mdi mdi-plus-circle-outline' style={{ fontSize: 18, marginLeft: 4 }} />
            </Button>
          </div>
        </div>
        <div style={styles.content}>
          <div className='flex flex--space-between' style={styles.topBar}>
            <div className='flex flex--align-center' style={styles.breadcrumbs}>
              <i className='mdi mdi-home' style={{ fontSize: 16 }} />
              <i className='mdi mdi-chevron-right' style={{ color: '#ccc', margin: '0px 5px' }} />
              <span>Products</span>
            </div>
            <div className='flex flex--align-center'>
              <div className='flex flex--align-center' style={styles.search}>
                <input
                  onBlur={this._clearSearch}
                  onChange={this._search}
                  onFocus={this._search}
                  placeholder='Search Products...'
                  style={styles.searchInput} type='text'
                />
                <i className='mdi mdi-magnify' style={{ color: Colors.GRAY.hex, fontSize: 20 }} />
                {this.state.search_results.length ? (
                  <div style={styles.searchResults}>
                    {this.state.search_results.map(product => (
                      <div
                        key={product.id}
                        onMouseDown={this._toggleProductForm.bind(null, true, product)}
                        style={styles.searchResultsItem}
                      >
                        <div style={{ fontWeight: 700, marginBottom: 4 }}>{product.product_name}</div>
                        <div style={{ fontSize: 12, color: '#777' }}>{product.product_type} - {product.product_type_detail}</div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <Button
                onClick={this._toggleProductForm.bind(null, true, null)}
              >
                New Product <i className='mdi mdi-plus-circle-outline' style={{ fontSize: 18, marginLeft: 4 }} />
              </Button>
            </div>
          </div>

          <Route
            exact={true}
            path='/products'
            render={() => (
              <List filters={{ search: this.state.search }} onProductClick={this._toggleProductForm.bind(null, true)} />
            )}
          />

          {sorted_product_groups.map(product_group => {
            return (
              <Route
                key={product_group.id}
                path={`/products/${product_group.id}`}
                render={() => (
                  <List filters={{ search: this.state.search, product_group_id: product_group.id }} onProductClick={this._toggleProductForm.bind(null, true)} />
                )}
              />
            );
          })}

          {this.state.show_product_form ? (
            <ProductForm
              onClose={this._toggleProductForm.bind(null, false, null)}
              onDelete={this._handleProductDeleted}
              onSave={this._handleProductSaved}
              product={this.state.product}
              show={true}
            />
          ) : null}

          {this.state.show_product_group_form ? (
            <ProductGroupForm
              onClose={this._toggleProductGroupForm.bind(null, false, null)}
              onDelete={this._handleProductGroupDeleted}
              onSave={this._handleProductGroupSaved}
              productGroup={this.state.product_group}
              show={true}
            />
          ) : null}
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      productMenu: {
        width: 300,
        flexShrink: 0,
        padding: '0px 30px',
        margin: '30px 0px',
        borderRight: '1px solid #e5e5e5'
      },
      menuItem: {
        display: 'block',
        textDecoration: 'none',
        color: Colors.GRAY.hex,
        fontSize: 14,
        padding: '12px 10px',
        margin: '14px 0px'
      },
      menuItemIcon: {
        fontSize: 20,
        cursor: 'pointer',
        marginLeft: 4,
        color: Colors.BLUE.hex
      },
      active: {
        fontWeight: 700,
        backgroundColor: `rgba(${Colors.BLUE.rgb}, 0.10)`,
        borderRadius: 8
      },
      content: {
        width: '100%',
        padding: '30px 40px'
      },
      topBar: {
        marginBottom: 40
      },
      breadcrumbs: {
        fontSize: 14,
        color: '#777'
      },
      search: {
        height: 40,
        borderRadius: this.state.search_results.length ? '4px 4px 0 0' : 4,
        border: '1px solid #ccc',
        marginRight: 12,
        paddingRight: 12,
        width: 300,
        position: 'relative'
      },
      searchInput: {
        border: 'none',
        padding: '10px 8px 10px 16px',
        lineHeight: 1,
        borderRadius: 20,
        backgroundColor: 'transparent',
        width: '100%'
      },
      searchResults: {
        position: 'absolute',
        background: '#fff',
        left: -1,
        right: -1,
        bottom: 0,
        transform: 'translateY(100%)',
        border: '1px solid #ccc',
        borderRadius: '0px 0px 4px 4px',
        boxShadow: '0px 10px 20px -10px rgba(0,0,0,0.3)',
        maxHeight: 300,
        overflow: 'auto'
      },
      searchResultsItem: {
        padding: 15,
        cursor: 'pointer',
        borderBottom: '1px solid #e5e5e5',
        marginBottom: -1
      }
    };
  }
}

export default Products;
