import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Colors } from 'constants/Styles';

const Tabs = props => {
  const styles = {
    menu: {
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #e5e5e5',
      borderBottom: '1px solid #e5e5e5'
    },
    menuItem: {
      padding: '16px 20px',
      marginRight: 20,
      fontSize: 14,
      color: '#777',
      lineHeight: 1,
      cursor: 'pointer',
      textDecoration: 'none'
    },
    menuItemActive: {
      padding: '16px 20px 12px 20px',
      fontWeight: 700,
      color: Colors.GRAY.hex,
      borderBottom: `4px solid ${Colors.BLUE.hex}`
    }
  };

  return (
    <div style={styles.menu}>
      {props.links.map((l, i) => (
        <NavLink
          activeStyle={styles.menuItemActive}
          exact={l.exact}
          key={i}
          style={styles.menuItem}
          to={l.to}
        >
          {l.label}
        </NavLink>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
    exact: PropTypes.bool
  }))
};

export default Tabs;
