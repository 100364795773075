import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from 'constants/Styles';

const Field = (props) => {
  const styles = {
    field: Object.assign({}, {
      marginBottom: 22
    }, props.style),
    label: {
      display: 'block',
      marginBottom: 6,
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1,
      color: Colors.GRAY.hex
    },
    labelDescription: {
      fontSize: 13,
      color: Colors.TEXT.hex,
      lineHeight: '18px',
      fontWeight: 400
    }
  };

  return (
    <div className={props.className} style={styles.field}>
      {props.label ? <label style={styles.label}>{props.label}</label> : null}
      {props.labelDescription ? <div style={styles.labelDescription}>{props.labelDescription}</div> : null}
      {props.children}
    </div>
  );
};

Field.propTypes = {
  label: PropTypes.string,
  labelDescription: PropTypes.node
};

export default Field;

