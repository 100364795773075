import React from 'react';

class Logo extends React.Component {
  render () {
    return (
      <svg style={this.props.style} viewBox='0 0 255 61'>
        <path d='M1.848,59.8 C1.2879972,59.8 0.84000168,59.6180018 0.504,59.254 C0.16799832,58.8899982 -7.46069873e-14,58.4280028 -7.46069873e-14,57.868 L-7.46069873e-14,2.932 C-7.46069873e-14,2.3719972 0.16799832,1.91000182 0.504,1.546 C0.84000168,1.18199818 1.2879972,1 1.848,1 L6.048,1 C6.6080028,1 7.05599832,1.18199818 7.392,1.546 C7.72800168,1.91000182 7.896,2.3719972 7.896,2.932 L7.896,52.744 L35.952,52.744 C36.5680031,52.744 37.0579982,52.9259982 37.422,53.29 C37.7860018,53.6540018 37.968,54.1159972 37.968,54.676 L37.968,57.868 C37.968,58.4280028 37.772002,58.8899982 37.38,59.254 C36.987998,59.6180018 36.5120028,59.8 35.952,59.8 L1.848,59.8 Z' fill='#FFFFFF' />
        <circle
          cx='49.888'
          cy='5.08'
          fill='#FFFFFF'
          r='4.28'
        />
        <path d='M47.964,59.8 C47.4039972,59.8 46.9420018,59.6180018 46.578,59.254 C46.2139982,58.8899982 46.032,58.4280028 46.032,57.868 L46.032,20.052 C46.032,19.4919972 46.2139982,19.0300018 46.578,18.666 C46.9420018,18.3019982 47.4039972,18.12 47.964,18.12 L51.66,18.12 C52.2200028,18.12 52.6819982,18.3019982 53.046,18.666 C53.4100018,19.0300018 53.592,19.4919972 53.592,20.052 L53.592,57.868 C53.592,58.4280028 53.4100018,58.8899982 53.046,59.254 C52.6819982,59.6180018 52.2200028,59.8 51.66,59.8 L47.964,59.8 Z' fill='#FFFFFF' fillRule='nonzero' />
        <path d='M72.492,59.8 C71.9319972,59.8 71.4700018,59.6180018 71.106,59.254 C70.7419982,58.8899982 70.56,58.4280028 70.56,57.868 L70.56,25.588 L63.336,25.588 C62.7759972,25.588 62.3140018,25.4200017 61.95,25.084 C61.5859982,24.7479983 61.404,24.3000028 61.404,23.74 L61.404,21.052 C61.404,20.4919972 61.5859982,20.0300018 61.95,19.666 C62.3140018,19.3019982 62.7759972,19.12 63.336,19.12 L70.56,19.12 L70.56,14.92 C70.56,10.3839773 71.6659889,6.87001246 73.878,4.378 C76.0900111,1.88598754 79.7159748,0.64 84.756,0.64 L89.712,0.64 C90.2720028,0.64 90.7339982,0.82199818 91.098,1.186 C91.4620018,1.55000182 91.644,2.0119972 91.644,2.572 L91.644,5.26 C91.644,5.8200028 91.4620018,6.26799832 91.098,6.604 C90.7339982,6.94000168 90.2720028,7.108 89.712,7.108 L84.924,7.108 C82.3479871,7.108 80.556005,7.77999328 79.548,9.124 C78.539995,10.4680067 78.036,12.539986 78.036,15.34 L78.036,19.12 L88.872,19.12 C89.4320028,19.12 89.8939982,19.3019982 90.258,19.666 C90.6220018,20.0300018 90.804,20.4919972 90.804,21.052 L90.804,23.74 C90.804,24.3000028 90.6220018,24.7479983 90.258,25.084 C89.8939982,25.4200017 89.4320028,25.588 88.872,25.588 L78.036,25.588 L78.036,57.868 C78.036,58.4280028 77.8540018,58.8899982 77.49,59.254 C77.1259982,59.6180018 76.6640028,59.8 76.104,59.8 L72.492,59.8 Z' fill='#FFFFFF' fillRule='nonzero' />
        <path d='M115.584,15.28 C121.744031,15.28 126.517983,17.2399804 129.906,21.16 C133.294017,25.0800196 134.988,30.3999664 134.988,37.12 L134.988,38.632 C134.988,39.1920028 134.806002,39.6539982 134.442,40.018 C134.077998,40.3820018 133.616003,40.564 133.056,40.564 L103.908,40.564 L103.908,41.32 C104.076001,44.9600182 105.19599,48.0679871 107.268,50.644 C109.34001,53.2200129 112.083983,54.508 115.5,54.508 C118.132013,54.508 120.273992,53.9900052 121.926,52.954 C123.578008,51.9179948 124.823996,50.8400056 125.664,49.72 C126.168003,49.0479966 126.559999,48.6420007 126.84,48.502 C127.120001,48.3619993 127.595997,48.292 128.268,48.292 L131.964,48.292 C132.468003,48.292 132.901998,48.4319986 133.266,48.712 C133.630002,48.9920014 133.812,49.3559978 133.812,49.804 C133.812,51.0360062 133.056008,52.5199913 131.544,54.256 C130.031992,55.9920087 127.890014,57.4899937 125.118,58.75 C122.345986,60.0100063 119.168018,60.64 115.584,60.64 C109.871971,60.64 105.308017,58.8620178 101.892,55.306 C98.4759829,51.7499822 96.6000017,46.8920308 96.264,40.732 L96.18,37.876 L96.264,35.104 C96.6000017,29.0559698 98.4759829,24.2400179 101.892,20.656 C105.308017,17.0719821 109.871971,15.28 115.584,15.28 Z M115.584,21.328 C111.943982,21.328 109.08801,22.5599877 107.016,25.024 C104.94399,27.4880123 103.908,30.6799804 103.908,34.6 L103.908,34.852 L127.344,34.852 L127.344,34.6 C127.344,30.6799804 126.280011,27.4880123 124.152,25.024 C122.023989,22.5599877 119.168018,21.328 115.584,21.328 Z' fill='#FFFFFF' fillRule='nonzero' />
        <g id='Group-2' transform='translate(144.608000, 0.000000)'>
          <path d='M22.392,1 C38.7468818,1 47.1706308,8.46436696 47.6634924,23.3933226 L47.676,36.868 C47.4519989,44.7080392 45.33802,50.4899814 41.334,54.214 C37.32998,57.9380186 31.1560417,59.8 22.812,59.8 L5,59.8 C1.73974609,59.8 7.10542736e-15,58.0452393 7.10542736e-15,54.8 L7.10542736e-15,6 C7.10542736e-15,2.34138184 1.896,1 5,1 L22.392,1 Z M21.972,12.7437022 L13.3829346,12.7437022 L13.3829346,48.0562902 L22.392,48.0562902 C27.0400232,48.0562902 30.2153432,46.5221568 32.2873535,44.4501465 C34.3593639,42.3781361 34.6359994,41.4320241 34.748,36.616 L34.748,36.616 L34.7333466,24.6448579 C34.5583157,20.2843992 34.3985641,18.1683234 32.2873535,16.0841797 C30.1033426,13.9281689 26.6760235,12.7437022 21.972,12.7437022 L21.972,12.7437022 Z' fill='#FFFFFF' fillRule='nonzero' />
          <g id='Group' transform='translate(51.000000, 0.000000)'>
            <path d='M26.8795659,0.8 C25.362461,0.8 23.6067267,0.8 21.6123628,0.8 C19.9034179,0.8 18.3000925,1.66392576 17.3092336,3.11867106 L0.978380844,27.0950736 C-0.325905406,29.0099823 -0.326147424,31.5778986 0.977777851,33.4930756 L17.3092891,57.4804435 C18.3000865,58.9357052 19.9037092,59.8 21.6130212,59.8 L26.8795659,59.8 C29.1552188,59.8 31,57.872575 31,55.4949757 C31,54.6000663 30.7330705,53.7274009 30.236336,52.9983502 L16.9476063,33.4946544 C15.6422474,31.5787961 15.6424897,29.009085 16.9482098,27.0934954 L30.2350537,7.60069589 C30.7478536,6.70035531 30.7478536,6.70035531 30.856183,6.17337563 C31.6143444,2.48522089 28.7271952,0.8 26.8795659,0.8 Z' fill='#FFFFFF' transform='translate(15.500000, 30.300000) scale(-1, 1) translate(-15.500000, -30.300000) ' />
            <path d='M56.8733243,15.8 C56.0903025,15.8 55.184117,15.8 54.1547679,15.8 C53.2727318,15.8 52.445209,16.2392843 51.933798,16.9789853 L43.5049708,29.1703764 C42.8317908,30.1440588 42.8316658,31.4497789 43.5046595,32.4235978 L51.9338266,44.6205645 C52.445206,45.3605281 53.2728822,45.8 54.1551077,45.8 L56.8733243,45.8 C58.0478549,45.8 59,44.8199534 59,43.6110046 C59,43.1559659 58.8622299,42.7122378 58.6058508,42.341534 L51.7471517,32.4244006 C51.073418,31.4502353 51.0735431,30.1436026 51.7474631,29.1695739 L58.605189,19.257981 C58.8698599,18.8001807 58.8698599,18.8001807 58.9257719,18.5322249 C59.317081,16.656892 57.8269395,15.8 56.8733243,15.8 Z' fill='#FFFFFF' />
            <path d='M34.2953987,0.8 C37.1029952,0.8 38.8987724,0.8 39.6827302,0.8 C41.4317245,0.8 43.0723571,1.68221625 44.0848844,3.16716746 L49.0032853,10.3803902 C50.3313005,12.3280294 50.332324,14.9368486 49.0058375,16.8856169 L47.1551131,19.6045536 C45.5588374,21.9496745 42.4390441,22.5033349 40.1868598,20.8411887 C39.7308252,20.5046283 39.3322368,20.0908016 39.0076847,19.6169329 L30.8668919,7.73081015 C29.0302786,5.45970126 30.084004,0.8 34.2953987,0.8 Z' fill='#FF2486' transform='translate(40.000000, 11.300000) scale(-1, 1) translate(-40.000000, -11.300000)' />
            <path d='M34.2953987,38.8 C37.1029952,38.8 38.8987724,38.8 39.6827302,38.8 C41.4317245,38.8 43.0723571,39.6822162 44.0848844,41.1671675 L49.0032853,48.3803902 C50.3313005,50.3280294 50.332324,52.9368486 49.0058375,54.8856169 L47.1551131,57.6045536 C45.5588374,59.9496745 42.4390441,60.5033349 40.1868598,58.8411887 C39.7308252,58.5046283 39.3322368,58.0908016 39.0076847,57.6169329 L30.8668919,45.7308102 C29.0302786,43.4597013 30.084004,38.8 34.2953987,38.8 Z' fill='#0497FF' transform='translate(40.000000, 49.300000) scale(-1, -1) translate(-40.000000, -49.300000)' />
          </g>
        </g>
      </svg>
    );
  }
}

export default Logo;

