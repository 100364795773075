import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';

const ZeroState = ({ title, description, icon, button, style }) => {
  const styles = {
    component: Object.assign({}, {
      padding: 60,
      textAlign: 'center'
    }, style),
    content: {
      width: '50%',
      minWidth: 300
    },
    iconWrapper: {
      background: '#f5f5f5',
      borderRadius: 100,
      width: 100,
      height: 100,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginBottom: 30
    },
    icon: {
      fontSize: 100,
      marginBottom: -20
    },
    title: {
      marginBottom: 10
    },
    description: {
      color: '#999'
    }
  };

  return (
    <div className='flex flex--align-center flex--justify-center flex--column' style={styles.component}>
      <div style={styles.content}>
        {icon ? <div style={styles.iconWrapper}><i className={`mdi mdi-${icon}`} style={styles.icon} /></div> : null}
        {title ? <h3 style={styles.title}>{title}</h3> : null}
        {description ? <p style={styles.description}>{description}</p> : null}
        {button ? <Button style={styles.button} {...button} /> : null}
      </div>
    </div>
  );
};

ZeroState.propTypes = {
  button: PropTypes.object,
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
};

export default ZeroState;

