import React from 'react';
import { Route } from 'react-router-dom';
import _debounce from 'lodash/debounce';

import Button from 'shared/Button';

import List from './List';
import AddVendor from './AddVendor';
import Show from './Show';

import { Colors } from 'constants/Styles';

import Api from 'utils/Api';

import { AuthContext } from 'src/AuthContext';

class Vendors extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      show_new_vendor: false,
      search_results: [],
      filter: null
    };

    this.searchVendors = _debounce(e => {
      const search_string = e.target.value;

      if (search_string.length > 2) {
        Api.searchVendorRelationships(search_string)
          .then(response => {
            this.setState({
              search_results: response.data
            });
          });
      } else {
        this.setState({
          search_results: []
        });
      }
    }, 400);
  }

  _search = (e) => {
    e.persist();
    this.searchVendors(e);
  }

  _clearSearch = e => {
    this.setState({ search_results: [] });

    e.target.value = '';
  }

  _handleVendorAdded = vendor => {
    this._toggleAddVendor(false);

    this.props.history.push(`/partners/details/${vendor.id}`);
  }

  _toggleAddVendor = show => {
    this.setState({ show_new_vendor: show });
  }

  _handleVendorClick = vendor => {
    this.props.history.push(`/partners/details/${vendor.id}`);
  }

  _handleFilterChange = filter => {
    this.props.history.push('/partners');
    this.setState({ filter });
  }

  render () {
    const styles = this.styles();
    const carrier_product_permissions = this.context.user.carrier_product_permissions;
    const { filter } = this.state;

    return (
      <div className='flex w--100 h--100'>
        <div style={styles.productMenu}>
          <h2>Partners</h2>

          <div style={styles.menu}>
            <div
              onClick={this._handleFilterChange.bind(null, null)}
              style={Object.assign({}, styles.menuItem, !filter && styles.active)}
            >
              All
            </div>

            {carrier_product_permissions.includes('life_quoting_api') ? (
              <div
                onClick={this._handleFilterChange.bind(null, 'li_quotes')}
                style={Object.assign({}, styles.menuItem, filter === 'li_quotes' && styles.active)}
              >
                Life Quotes API
              </div>
            ) : null}

            {carrier_product_permissions.includes('ltc_quoting_api') ? (
              <div
                onClick={this._handleFilterChange.bind(null, 'ltc_quotes')}
                style={Object.assign({}, styles.menuItem, filter === 'ltc_quotes' && styles.active)}
              >
                LTC Quotes API
              </div>
            ) : null}

            {carrier_product_permissions.includes('forms_api') ? (
              <div
                onClick={this._handleFilterChange.bind(null, 'forms')}
                style={Object.assign({}, styles.menuItem, filter === 'forms' && styles.active)}
              >
                Forms API
              </div>
            ) : null}
          </div>
        </div>
        <div style={styles.content}>
          <div className='flex flex--space-between' style={styles.topBar}>
            <div className='flex flex--align-center' style={styles.breadcrumbs}>
              <i className='mdi mdi-home' style={{ fontSize: 16 }} />
              <i className='mdi mdi-chevron-right' style={{ color: '#ccc', margin: '0px 5px' }} />
              <span>Partners</span>
            </div>
            <div className='flex flex--align-center'>
              <div className='flex flex--align-center' style={styles.search}>
                <input
                  onBlur={this._clearSearch}
                  onChange={this._search}
                  onFocus={this._search}
                  placeholder='Search Partners...'
                  style={styles.searchInput} type='text'
                />
                <i className='mdi mdi-magnify' style={{ color: Colors.GRAY.hex, fontSize: 20 }} />
                {this.state.search_results.length ? (
                  <div style={styles.searchResults}>
                    {this.state.search_results.map((v, i) => (
                      <div
                        key={i}
                        onMouseDown={this._handleVendorClick.bind(null, v)}
                        style={styles.searchResultsItem}
                      >
                        <div style={{ fontWeight: 700, marginBottom: 4 }}>{v.name}</div>
                        <div style={{ fontSize: 12, color: '#777' }}>{v.business_type}</div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <Button
                onClick={this._toggleAddVendor.bind(null, true)}
              >
                Add Partner <i className='mdi mdi-plus-circle-outline' style={{ fontSize: 18, marginLeft: 4 }} />
              </Button>
            </div>
          </div>

          <Route exact={true} path='/partners' render={props => <List {...props} filters={{ 'permission': filter }} key={filter} />} />
          <Route path='/partners/details/:id' render={props => <Show {...props} key={props.match.params.id} />} />

          {this.state.show_new_vendor ? (
            <AddVendor
              onClose={this._toggleAddVendor.bind(null, false)}
              onSave={this._handleVendorAdded}
              show={true}
            />
          ) : null}
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      productMenu: {
        width: 230,
        flexShrink: 0,
        padding: '0px 30px',
        margin: '30px 0px',
        borderRight: '1px solid #e5e5e5'
      },
      menuItem: {
        display: 'block',
        textDecoration: 'none',
        color: Colors.GRAY.hex,
        fontSize: 14,
        padding: '12px 10px',
        margin: '14px 0px',
        cursor: 'pointer'
      },
      active: {
        fontWeight: 700,
        backgroundColor: `rgba(${Colors.BLUE.rgb}, 0.10)`,
        borderRadius: 8
      },
      content: {
        width: '100%',
        padding: '30px 40px'
      },
      topBar: {
        marginBottom: 40
      },
      breadcrumbs: {
        fontSize: 14,
        color: '#777'
      },
      search: {
        height: 40,
        borderRadius: this.state.search_results.length ? '4px 4px 0 0' : 4,
        border: '1px solid #ccc',
        marginRight: 12,
        paddingRight: 12,
        width: 300,
        position: 'relative'
      },
      searchInput: {
        border: 'none',
        padding: '10px 8px 10px 16px',
        lineHeight: 1,
        borderRadius: 20,
        backgroundColor: 'transparent',
        width: '100%'
      },
      searchResults: {
        position: 'absolute',
        background: '#fff',
        left: -1,
        right: -1,
        bottom: 0,
        transform: 'translateY(100%)',
        border: '1px solid #ccc',
        borderRadius: '0px 0px 4px 4px',
        boxShadow: '0px 10px 20px -10px rgba(0,0,0,0.3)',
        maxHeight: 300,
        overflow: 'auto'
      },
      searchResultsItem: {
        padding: 15,
        cursor: 'pointer',
        borderBottom: '1px solid #e5e5e5',
        marginBottom: -1
      },
      pill: {
        display: 'inline-block',
        height: 22,
        lineHeight: '22px',
        padding: '0px 12px',
        fontSize: 10,
        fontWeight: 700,
        color: '#fff',
        letterSpacing: 1,
        textTransform: 'uppercase',
        backgroundColor: '#444',
        borderRadius: 22
      }
    };
  }
}

Vendors.contextType = AuthContext;

export default Vendors;
