import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Colors } from 'constants/Styles';

const PowerSelect = props => {
  const styles = {
    select: {
      control: () => Object.assign({}, {
        alignItems: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: props.invalid ? Colors.RED.hex : '#ccc',
        borderRadius: 4,
        width: '100%',
        cursor: 'default',
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: 14,
        justifyContent: 'space-between',
        minHeight: 46,
        position: 'relative',
        padding: '4px 0px'
      }, props.style),
      menu: provider => Object.assign({}, provider, {
        borderRadius: 2
      }),
      multiValue: provider => Object.assign({}, provider, {
        backgroundColor: Colors.GREEN.hex,
        borderRadius: 3,
        padding: '5px 4px 5px 8px',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '2px 4px 2px 0px'
      }),
      multiValueLabel: provider => Object.assign({}, provider, {
        color: '#fff',
        fontSize: 13,
        fontWeight: 700,
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        margin: 0
      }),
      multiValueRemove: provider => Object.assign({}, provider, {
        color: '#fff',
        paddingLeft: 2,
        paddingTop: 0,
        paddingRight: 2,
        paddingBottom: 0,
        marginLeft: 4,
        cursor: 'pointer'
      })
    },
    required: {
      position: 'absolute',
      right: 5,
      top: 5,
      color: Colors.RED.hex
    }
  };
  const components = {
    IndicatorSeparator: null
  };

  if (!props.options) {
    components.DropdownIndicator = null;
  }

  return (
    <div style={{ position: 'relative' }}>
      {props.creatable ? (
        <CreatableSelect
          components={components}
          formatCreateLabel={inputValue => `Add '${inputValue}'`}
          isClearable={false}
          isMulti={props.isMulti}
          isValidNewOption={props.newOptionCheck}
          noOptionsMessage={() => props.noOptionsMessage}
          onChange={props.onChange}
          placeholder={props.placeholder}
          styles={styles.select}
          value={props.value}
        />
      ) : (
        <Select
          components={components}
          isClearable={false}
          isMulti={props.isMulti}
          onChange={props.onChange}
          options={props.options}
          placeholder={props.placeholder}
          styles={styles.select}
          value={props.value}
        />
      )}
      {props.required ? (
        <span style={styles.required}>*</span>
      ) : null}
    </div>
  );
};

PowerSelect.propTypes = {
  creatable: PropTypes.bool,
  invalid: PropTypes.bool,
  isMulti: PropTypes.bool,
  newOptionCheck: PropTypes.func,
  noOptionsMessage: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object])
};

export default PowerSelect;
