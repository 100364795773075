import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';

const Dialog = (props) => {
  const styles = {
    wrapper: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 20,
      background: 'rgba(0,0,0,0.4)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    dialog: {
      background: '#fff',
      borderRadius: 8,
      textAlign: 'center',
      padding: 40
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center'
    }
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.dialog}>
        <h3>{props.message}</h3>
        <div style={styles.buttons}>
          {props.onDismiss ? <Button color={props.dismissColor || 'GRAY'} onClick={props.onDismiss} style={{ marginRight: 10 }}>{props.dismissLabel}</Button> : null}
          <Button color={props.confirmColor || 'BLUE'} onClick={props.onConfirm}>{props.confirmLabel}</Button>
        </div>
      </div>
    </div>
  );
};

Dialog.propTypes = {
  confirmColor: PropTypes.string,
  confirmLabel: PropTypes.string,
  dismissColor: PropTypes.string,
  dismissLabel: PropTypes.string,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func
};

export default Dialog;

