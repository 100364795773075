import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import Moment from 'moment';

import Table from 'shared/Table';

import { Colors } from 'constants/Styles';

import { AuthContext } from 'src/AuthContext';

class Activity extends React.Component {
  static propTypes = {
    vendor: PropTypes.object
  }

  constructor (props) {
    super(props);

    this.usageChart = null;
    this.canvasRef = React.createRef();
  }

  componentDidMount () {
    const datasets = [];
    const { activity } = this.props.vendor;
    const has_activity = activity.recent_requests.length;
    const clean_activity = {
      life_quote_requests: [],
      form_requests: []
    };

    let days_ago = 14;

    while (days_ago >= 0) {
      const day = Moment().subtract(days_ago, 'days').format('DD-MM-YYYY');
      const quote_point = {
        x: day
      };
      const form_point = {
        x: day
      };

      if (has_activity) {
        const quote_data = activity.recent_quote_counts.find(qr => qr.day_month_year === day);
        const form_data = activity.recent_form_counts.find(fr => fr.day_month_year === day);

        quote_point.y = quote_data ? quote_data.count : 0;
        form_point.y = form_data ? form_data.count : 0;
      } else {
        quote_point.y = Math.floor((Math.random() * 2000));
        form_point.y = Math.floor((Math.random() * 2000));
      }

      clean_activity.life_quote_requests.push(quote_point);
      clean_activity.form_requests.push(form_point);

      days_ago--;
    }

    if (
      this.context.user.carrier_product_permissions.includes('life_quoting_api') &&
      this.props.vendor.permissions.li_quotes && this.props.vendor.permissions.li_quotes.length
    ) {
      datasets.push({
        label: 'Life Quote Requests',
        data: clean_activity.life_quote_requests,
        backgroundColor: `rgba(${Colors.BLUE.rgb}, 0.4)`,
        borderColor: `rgb(${Colors.BLUE.rgb})`,
        pointHoverBackgroundColor: `rgb(${Colors.BLUE.rgb})`,
        borderWidth: 2,
        hoverBorderWidth: 2
      });
    }

    if (
      this.context.user.carrier_product_permissions.includes('forms_api') &&
      this.props.vendor.permissions.forms && this.props.vendor.permissions.forms.length
    ) {
      datasets.push({
        label: 'Form Requests',
        data: clean_activity.form_requests,
        backgroundColor: `rgba(${Colors.GREEN.rgb}, 0.4)`,
        borderColor: `rgb(${Colors.GREEN.rgb})`,
        pointHoverBackgroundColor: `rgb(${Colors.GREEN.rgb})`,
        borderWidth: 2,
        hoverBorderWidth: 2
      });
    }

    this.usageChart = new Chart(this.canvasRef.current, {
      type: 'line',
      fill: true,
      data: { datasets },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            usePointStyle: true,
            padding: 20,
            generateLabels (chart) {
              chart.legend.afterFit = function () {
                this.height = this.height + 20;
              };

              return chart.data.datasets.map(function (dataset, i) {
                return {
                  text: dataset.label,
                  fillStyle: dataset.backgroundColor,
                  hidden: !chart.isDatasetVisible(i),
                  lineCap: dataset.borderCapStyle,
                  lineDash: dataset.borderDash,
                  lineDashOffset: dataset.borderDashOffset,
                  lineJoin: dataset.borderJoinStyle,
                  lineWidth: dataset.borderWidth,
                  strokeStyle: dataset.borderColor,
                  pointStyle: dataset.pointStyle,

                  // Below is extra data used for toggling the datasets
                  datasetIndex: i
                };
              }, this);
            }
          }
        },
        tooltips: {
          titleMarginBottom: 10,
          bodySpacing: 10,
          backgroundColor: `rgba(${Colors.GRAY.rgb}, 0.8)`,
          xPadding: 12,
          yPadding: 12,
          callbacks: {
            label (tooltipItem, data) {
              let label = ' ';

              label += data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ' - ';
              }

              label += Math.round(tooltipItem.yLabel * 100) / 100;

              return label;
            }
          }
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              tooltipFormat: 'M/D',
              unit: 'day',
              displayFormats: {
                day: 'M/D'
              },
              parser: 'DD-MM-YYYY'
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Requests'
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  render () {
    const styles = this.styles();

    return (
      <React.Fragment>
        <div style={styles.chart}>
          <canvas
            ref={this.canvasRef}
            style={styles.canvas}
          />
          <div style={styles.chartOverlay}>
            <div style={styles.chartOverlayContent}>
              No data available for the last 14 Days
            </div>
          </div>
        </div>

        <Table
          columns={[
            {
              id: 'api',
              label: 'API',
              is_sortable: true
            },
            {
              id: 'uid',
              label: 'ID',
              is_sortable: true
            },
            {
              id: 'created_at',
              label: 'Requested On',
              is_sortable: true,
              render: r => Moment(r.created_at).format('MMM Do @ h:mm a')
            }
          ]}
          data={this.props.vendor.activity.recent_requests}
          defaultSortColumn={{ id: 'created_on' }}
          perPage={6}
        />
      </React.Fragment>
    );
  }

  styles = () => {
    // const has_activity = this.props.vendor.activity.recent_requests.length;

    return {
      chart: {
        position: 'relative'
      },
      canvas: {
        width: '100%',
        height: 400,
        maxHeight: 400,
        margin: '40px 0px'
      },
      chartOverlay: {
        display: 'none', // has_activity ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 10,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(255,255,255,0)'
      },
      chartOverlayContent: {
        fontSize: 14,
        fontStyle: 'italic',
        color: '#777',
        background: '#fff',
        padding: 20,
        borderRadius: 8,
        border: '1px solid #e5e5e5',
        marginBottom: 40,
        textAlign: 'center'
      }
    };
  }
}

Activity.contextType = AuthContext;

export default Activity;
