import React from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import _range from 'lodash/range';

import { Colors } from 'constants/Styles';

class Table extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      isSortable: PropTypes.boolean,
      label: PropTypes.node,
      render: PropTypes.func,
      styles: PropTypes.object
    })).isRequired,
    data: PropTypes.array.isRequired,
    defaultSortColumn: PropTypes.object,
    defaultSortDirection: PropTypes.string,
    hideHeader: PropTypes.bool,
    onPageClick: PropTypes.func,
    onRowClick: PropTypes.func,
    onSort: PropTypes.func,
    page: PropTypes.number,
    pagination: PropTypes.shape({
      total: PropTypes.number,
      total_pages: PropTypes.number,
      first_page: PropTypes.bool,
      last_page: PropTypes.bool,
      previous_page: PropTypes.bool,
      next_page: PropTypes.bool,
      out_of_bounds: PropTypes.bool,
      offset: PropTypes.number
    })
  }

  constructor (props) {
    super(props);
    this.state = {
      hover_index: null,
      sort_by_column: props.defaultSortColumn || {},
      sort_direction: props.defaultSortDirection || 'asc'
    };
  }

  _handleColumnSort = (column) => {
    if (column.is_sortable) {
      if (this.props.onSort) {
        this.props.onSort({ sort_by: column.id, sort_direction: this.state.sort_direction === 'asc' ? 'desc' : 'asc' });
      } else {
        this.setState({
          sort_by_column: column,
          sort_direction: this.state.sort_direction === 'asc' ? 'desc' : 'asc'
        });
      }
    }
  }

  _handleRowClick = (row_object) => {
    if (this.props.onRowClick) {
      this.props.onRowClick(row_object);
    }
  }

  _getSortedItems = () => {
    if (this.props.onSort) {
      return this.props.data;
    } else {
      return _orderBy(this.props.data || [], this.state.sort_by_column.sort_by || this.state.sort_by_column.render || this.state.sort_by_column.id, this.state.sort_direction);
    }
  }

  render () {
    const styles = this.styles();
    const sorted_data = this._getSortedItems();
    const pagination_data = this.props.pagination;
    const current_page = this.props.page;

    return (
      <div style={styles.component}>
        <table style={styles.table}>
          {this.props.hideHeader ? null : (
            <thead style={styles.thead}>
              <tr>
                {this.props.columns.map((column, i) => {
                  const column_styles = Object.assign(
                    {},
                    styles.header,
                    column.is_sortable && styles.sortable,
                    column.styles
                  );

                  return (
                    <th key={i} onClick={this._handleColumnSort.bind(null, column)} style={column_styles}>
                      {column.label}
                      {column.is_sortable && this.state.sort_by_column.id === column.id ? (
                        <i
                          className={`mdi ${this.state.sort_direction === 'asc' ? 'mdi-chevron-up' : 'mdi-chevron-down'}`}
                          style={{ fill: '#999', marginBottom: 2 }}
                        />
                      ) : null}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}

          {sorted_data.length ? (
            <tbody style={styles.tbody}>
              {sorted_data.map((row_object, i) => {
                const bg_color = i % 2 === 0 ? '#fff' : '#fcfcfc';
                const row_styles = Object.assign({
                  backgroundColor: this.state.hover_index === i && this.props.onRowClick ? '#f5f5f5' : bg_color
                }, styles.row);

                return (
                  <tr
                    key={i}
                    onClick={this._handleRowClick.bind(null, row_object)}
                    onMouseEnter={() => (this.props.onRowClick ? this.setState({ hover_index: i }) : null)}
                    onMouseLeave={() => (this.props.onRowClick ? this.setState({ hover_index: null }) : null)}
                    style={row_styles}
                  >
                    {this.props.columns.map((column, i) => {
                      return (
                        <td key={i} style={Object.assign({}, styles.column, column.styles)}>
                          {column.render ? column.render(row_object) : (row_object[column.id] || 'N/A')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : null}
        </table>

        {this.props.data && pagination_data ? (
          <div style={styles.pagination}>
            <div onClick={current_page === 1 ? null : this.props.onPageClick.bind(null, current_page - 1)} style={styles.page}>
              <i className='mdi mdi-chevron-left' style={styles.page_icon} />
            </div>

            {current_page > 2 ? (
              <div onClick={this.props.onPageClick.bind(null, 1)} style={Object.assign({}, styles.page, current_page === 1 && styles.page_current)}>
                1
              </div>
            ) : null}

            {current_page > 2 ? <i className='mdi mdi-dots-horizontal' style={styles.dots} /> : null}

            {_range(1, 2).map(i => {
              const page_num = current_page - (2 - i);

              if (page_num > 0) {
                return (
                  <div
                    key={page_num}
                    onClick={this.props.onPageClick.bind(null, page_num)}
                    style={styles.page}
                  >
                    {page_num}
                  </div>
                );
              } else {
                return null;
              }
            })}

            <div style={Object.assign({}, styles.page, styles.page_current)}>
              {current_page}
            </div>

            {_range(1, 2).map(i => {
              const page_num = current_page + i;

              if (page_num <= pagination_data.total_pages) {
                return (
                  <div key={page_num} onClick={this.props.onPageClick.bind(null, page_num)} style={styles.page}>
                    {page_num}
                  </div>
                );
              } else {
                return null;
              }
            })}

            {current_page < (pagination_data.total_pages - 1) ? <i className='mdi mdi-dots-horizontal' style={styles.dots} /> : null}

            {current_page < (pagination_data.total_pages - 1) ? (
              <div onClick={this.props.onPageClick.bind(null, pagination_data.total_pages)} style={Object.assign({}, styles.page, current_page === pagination_data.total_pages && styles.page_current)}>
                {pagination_data.total_pages}
              </div>
            ) : null}

            <div onClick={current_page === pagination_data.total_pages ? null : this.props.onPageClick.bind(null, current_page + 1)} style={styles.page}>
              <i className='mdi mdi-chevron-right' style={styles.page_icon} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  styles = () => {
    return {
      component: Object.assign({}, this.props.style),
      table: {
        width: '100%',
        tableLayout: 'auto'
      },
      header: {
        padding: 15,
        textAlign: 'left',
        fontWeight: 700,
        borderBottom: '1px solid #e5e5e5',
        borderTop: '1px solid #e5e5e5',
        textTransform: 'uppercase',
        fontSize: 12,
        letterSpacing: '1px',
        whiteSpace: 'nowrap'
      },
      sortable: {
        cursor: 'pointer',
        color: Colors.BLUE.hex
      },
      row: {
        cursor: this.props.onRowClick ? 'pointer' : 'default',
        transition: 'all .2s'
      },
      column: {
        padding: '30px 15px',
        wordBreak: 'break-word',
        verticalAlign: 'middle',
        fontSize: 14,
        color: '#777'
      },

      // PAGINATION
      pagination: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#fff',
        borderTop: '1px solid #e5e5e5'
      },
      page: {
        width: 34,
        height: 34,
        lineHeight: '34px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#e5e5e5',
        borderRadius: 34,
        margin: '0 5px',
        fontSize: 12,
        fontWeight: 700
      },
      page_current: {
        backgroundColor: Colors.BLUE.hex,
        color: '#fff'
      },
      page_icon: {
        fontSize: 18,
        fontWeight: 800
      },
      dots: {
        color: '#999',
        fontSize: 16
      }
    };
  }
}

export default Table;
