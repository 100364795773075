import React from 'react';

class Rates extends React.Component {
  render () {
    return (
      <div className='flex flex--align-center flex--justify-center h--100'>
        <div className='text--center'>
          <h2>Rates</h2>
          <p>Coming soon! Email <a href='mailto:sales@thelifedx.com'>sales@thelifedx.com</a> if you would like more information.</p>
        </div>
      </div>
    );
  }
}

export default Rates;
