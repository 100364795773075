import React from 'react';
import Cookie from 'js-cookie';

import Button from 'shared/Button';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Logo from 'shared/Logo';

import { RECAPTCHA_SITE_KEY } from '../../config';

import { Colors } from 'constants/Styles';
import { _validateEmail } from 'constants/Form';

import Api from 'utils/Api';

class Login extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errors: ['recaptcha'],
      show_recaptcha: false
    };

    window.showRecaptcha = () => {
      this.setState({ show_recaptcha: true });
    };
  }

  componentDidMount () {
    const existing_el = document.getElementById('recaptcha_script');

    if (!existing_el) {
      const el = document.createElement('script');

      el.src = 'https://www.google.com/recaptcha/api.js?onload=showRecaptcha&render=explicit';
      el.id = 'recaptcha_script';

      document.body.appendChild(el);
    }

    document.onkeypress = (e) => {
      if (e.key === 'Enter') {
        this._signIn();
      }
    };
  }

  componentWillUnmount () {
    const existing_el = document.getElementById('recaptcha_script');

    if (existing_el) {
      existing_el.parentNode.removeChild(existing_el);
    }

    document.onkeypress = null;
  }

  _updateField = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  }

  _reCaptchaSuccess = () => {
    const errors = this.state.errors.filter(e => e !== 'recaptcha');

    this.setState({ errors });
  }

  _reCaptchaError = () => {
    const errors = this.state.errors.filter(e => e !== 'recaptcha');

    errors.push('recaptcha');

    this.setState({ errors });
  }

  _signIn = () => {
    const { email, password } = this.state;
    const errors = this.state.errors.filter(e => e === 'recaptcha');

    if (!password || password.length < 6) {
      errors.push('password');
    }

    if (!_validateEmail(email)) {
      errors.push('email');
    }

    if (errors.length) {
      window.grecaptcha.reset();

      this.setState({ errors });
    } else {
      Api.login({ email: this.state.email, password: this.state.password })
        .then(response => {
          Cookie.set('dxp', {
            uid: response.headers.uid,
            client: response.headers.client,
            access_token: response.headers['access-token'],
            refresh_token: response.headers['refresh-token'],
            id: response.data.data.id
          }, { domain: window.location.hostname });

          window.location.reload();
        })
        .catch(e => {
          window.grecaptcha.reset();

          this.setState({
            errors: ['email', 'password', 'recaptcha']
          });
        });
    }
  }

  _recaptchaDivLoaded = el => {
    window.grecaptcha.render(el, {
      sitekey: RECAPTCHA_SITE_KEY,
      callback: this._reCaptchaSuccess,
      'expired-callback': this._reCaptchaError,
      'error-callback': this._reCaptchaError
    });
  }

  render () {
    const styles = this.styles();

    return (
      <div style={styles.component}>
        <div style={styles.header}>
          <Logo style={styles.logo} />
          <div style={styles.slant} />
        </div>
        <div style={styles.login_wrapper}>
          <div style={styles.login}>
            <Field label='Email'>
              <Input
                invalid={this.state.errors.includes('email')}
                onChange={this._updateField.bind(null, 'email')}
                required={true}
                type='text'
              />
            </Field>
            <Field label='Password'>
              <Input
                invalid={this.state.errors.includes('password')}
                onChange={this._updateField.bind(null, 'password')}
                required={true}
                type='password'
              />
            </Field>
            {this.state.show_recaptcha ? <div ref={this._recaptchaDivLoaded} /> : null}
            <Button onClick={this._signIn} style={{ marginTop: 18 }}>Sign In</Button>
          </div>
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        minHeight: '100vh',
      },
      header: {
        background: Colors.GRAY.hex,
        position: 'relative',
        height: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      slant: {
        background: Colors.GRAY.hex,
        height: 200,
        position: 'absolute',
        bottom: 0,
        left: '-5%',
        right: '-5%',
        zIndex: 0,
        transform: 'rotate(-5deg) translateY(50%)'
      },
      logo: {
        width: 120,
        height: 30,
        marginBottom: 40,
        position: 'relative',
        zIndex: 2
      },
      login_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        paddingTop: 80
      },
      login: {
        width: 384,
        background: '#fff',
        padding: 40,
        borderRadius: 8,
        marginTop: -100,
        zIndex: 4,
        border: '1px solid #eee',
        borderBottom: '3px solid #ddd'
      }
    };
  }
}

export default Login;
