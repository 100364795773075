import React from 'react';
import { Route } from 'react-router-dom';

import Loader from 'shared/Loader';
import Saved from 'shared/Saved';
import Tabs from 'shared/Tabs';
import Toggle from 'shared/Toggle';

import Details from './Details';
import VersionForm from './VersionForm';
import Versions from './Versions';
import Notes from './Notes';

import Api from 'utils/Api';

class Show extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      form: {},
      loading: true,
      saved: false,
      show_form: false,
      version: null
    };
  }

  componentDidMount () {
    Api.loadForm(this.props.match.params.id)
      .then(response => {
        this.setState({
          form: response.data,
          loading: false
        });
      });
  }

  _toggleForm = (show_form, version) => {
    this.setState({ show_form, version });
  }

  _toggleFormStatus = () => {
    const params = { is_active: !this.state.form.is_active };

    this.setState({
      form: Object.assign({}, this.state.form, params)
    });

    Api.saveForm(this.state.form.id, params)
      .then(() => {
        // no action needed
      });
  }

  _handleFormSave = form => {
    this.setState({ form, saved: true });

    setTimeout(() => {
      this.setState({
        saved: false
      });
    }, 2000);
  }

  _handleVersionSave = () => {
    this.setState({
      loading: true
    });

    Api.loadForm(this.props.match.params.id)
      .then(response => {
        this.setState({
          show_form: false,
          form: response.data,
          loading: false,
          version: null,
          saved: true
        });

        setTimeout(() => {
          this.setState({
            saved: false
          });
        }, 2000);
      });
  }

  render () {
    return this.state.loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <VersionForm
          form={this.state.form}
          key={this.state.version ? this.state.version.id : 'new'}
          onClose={this._toggleForm.bind(null, false, null)}
          onSave={this._handleVersionSave}
          show={this.state.show_form}
          version={this.state.version}
        />

        <div className='flex flex--space-between flex--align-center' style={{ margin: '40px 0 24px 0' }}>
          <h3 style={{ marginBottom: 0 }}>{this.state.form.name}</h3>
          <Toggle active={this.state.form.is_active} onClick={this._toggleFormStatus} />
        </div>

        <Tabs
          links={[
            { label: 'Details', to: `/forms/${this.state.form.id}`, exact: true },
            { label: 'Versions', to: `/forms/${this.state.form.id}/versions` },
            { label: 'Notes', to: `/forms/${this.state.form.id}/notes` }
          ]}
        />

        {this.state.saved ? <Saved /> : null}

        <Route
          exact={true}
          path='/forms/:id'
          render={routeProps => (
            <Details
              {...routeProps}
              form={this.state.form}
              onNewVersionClick={this._toggleForm.bind(null, true, null)}
            />
          )}
        />
        <Route
          path='/forms/:id/versions'
          render={routeProps => (
            <Versions
              {...routeProps}
              form={this.state.form}
              onVersionClick={version => {
                this._toggleForm(true, version);
              }}
            />
          )}
        />
        <Route
          path='/forms/:id/notes'
          render={routeProps => (
            <Notes
              {...routeProps}
              form={this.state.form}
              onSave={this._handleFormSave}
            />
          )}
        />
      </React.Fragment>
    );
  }
}

export default Show;
