import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Dialog from 'shared/Dialog';

import { Colors } from 'constants/Styles';
import { STATES } from 'constants/Form';

import { FormsContext } from './Context';

import Api from 'utils/Api';

class Details extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    onNewVersionClick: PropTypes.func
  }

  constructor (props) {
    super(props);

    this.state = {
      show_delete: false
    };
  }

  _toggleDeleteModal = () => {
    this.setState({
      show_delete: !this.state.show_delete
    });
  }

  _confirmDelete = () => {
    Api.deleteForm(this.props.form.id)
      .then(() => {
        this.props.history.push('/forms');
      });
  }

  render () {
    const styles = this.styles();
    const { form } = this.props;
    const { form_tags, product_groups } = this.context;

    return (
      <div style={styles.component}>
        {this.state.show_delete ? (
          <Dialog
            confirmColor='RED'
            confirmLabel='Yes, Delete It'
            dismissColor='BLUE'
            dismissLabel='Nevermind'
            message='Are you sure you want to delete this form?'
            onConfirm={this._confirmDelete}
            onDismiss={this._toggleDeleteModal}
          />
        ) : null}

        <div style={styles.preview_container}>
          {form.pdf_file && form.pdf_file.url ? (
            <iframe src={form.pdf_file.url} style={styles.preview} />
          ) : (
            <i className='mdi mdi-file-question' style={styles.preview_404} />
          )}
        </div>
        <div style={styles.details}>
          <div style={styles.section}>
            <div style={styles.section_header}>Number</div>
            <p>{form.number}</p>
          </div>

          <div style={styles.section}>
            <div style={styles.section_header}>Description</div>
            <p>{form.description}</p>
          </div>

          <div style={styles.section}>
            <div style={styles.section_header}>States</div>
            <p>
              {form.states.map(abbr => {
                const state = STATES.find(s => s.value === abbr) || {};

                return state.label;
              }).join(', ')}
            </p>
          </div>

          <div style={styles.section}>
            <div style={styles.section_header}>Tags</div>
            <p>
              {form.form_tag_ids.map(id => {
                const form_tag = form_tags.find(ft => ft.id === id) || {};

                return form_tag.name;
              }).join(', ')}
            </p>
          </div>

          <div style={styles.section}>
            <div style={styles.section_header}>Product Groups</div>
            <p>
              {form.product_group_ids.map(id => {
                const product_group = product_groups.find(pg => pg.id === id) || {};

                return product_group.group_name;
              }).sort().join(', ')}
            </p>
          </div>

          <div style={styles.sticky_button}>
            <Button fullWidth={true} onClick={this.props.onNewVersionClick}>Make Changes</Button>
            <div onClick={this._toggleDeleteModal} style={styles.delete}>Delete</div>
          </div>
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        display: 'flex',
        marginTop: 40
      },
      preview_container: {
        position: 'relative',
        paddingTop: '80%',
        width: 'calc(60% - 40px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#f5f5f5'
      },
      preview: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 'none'
      },
      preview_404: {
        fontSize: 100,
        color: '#e5e5e5',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      },
      details: {
        marginLeft: 40,
        width: '40%',
        flexShrink: 0
      },
      section: {
        marginBottom: 30
      },
      section_header: {
        color: Colors.BLUE.hex,
        textTransform: 'uppercase',
        fontSize: 11,
        letterSpacing: 1,
        fontWeight: 600,
        marginBottom: 10
      },
      sticky_button: {
        position: 'sticky',
        bottom: 0,
        padding: 20,
        background: '#fff',
        boxShadow: '0px -10px 30px -25px rgba(0,0,0,0.3)'
      },
      delete: {
        color: Colors.RED.hex,
        fontWeight: 600,
        cursor: 'pointer',
        textAlign: 'center',
        marginTop: 15
      }
    };
  }
}

Details.contextType = FormsContext;

export default Details;
