import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'shared/Loader';
import Table from 'shared/Table';
import ZeroState from 'shared/ZeroState';

import Api from 'utils/Api';

class List extends React.Component {
  static propTypes = {
    filters: PropTypes.object,
    onProductClick: PropTypes.func
  }

  constructor (props) {
    super(props);

    this.state = {
      products: [],
      loading: true
    };
  }

  componentDidMount () {
    Api.loadProducts(this.props.filters)
      .then(response => {
        this.setState({
          products: response.data,
          loading: false
        });
      });
  }

  render () {
    return this.state.products.length ? (
      <React.Fragment>
        <Table
          columns={[
            {
              id: 'product_name',
              label: 'Name',
              is_sortable: true
            },
            {
              id: 'product_type',
              label: 'Type',
              is_sortable: true
            },
            {
              id: 'product_type_detail',
              label: 'Detail',
              is_sortable: true
            }
          ]}
          data={this.state.products}
          defaultSortColumn={{ id: 'product_name' }}
          onRowClick={this.props.onProductClick}
        />
        {this.state.loading ? <Loader /> : null}
      </React.Fragment>
    ) : (
      <ZeroState
        description="This Product Group doesn't have any products associated with it yet. Click the button at the top right to create one."
        icon='package'
        title='Empty Product Group'
      />
    );
  }
}

export default List;
