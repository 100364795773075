import React from 'react';

import Button from 'shared/Button';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Loader from 'shared/Loader';
import Saved from 'shared/Saved';

import Api from 'utils/Api';

class User extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      user: null,
      loading: true,
      errors: [],
      saved: false
    };
  }

  componentDidMount () {
    Api.loadUser('me')
      .then(response => {
        this.setState({
          user: response.data,
          loading: false
        });
      });
  }

  _handleInputChange = (name, e) => {
    this.setState({
      user: Object.assign({}, this.state.user, {
        [name]: e.target.value
      })
    });
  }

  _save = () => {
    const { first_name, last_name } = this.state.user;
    const errors = [];

    if (!first_name || first_name.length < 4) {
      errors.push('first_name');
    }

    if (!last_name || last_name.length < 4) {
      errors.push('last_name');
    }

    if (errors.length) {
      this.setState({
        errors
      });
    } else {
      Api.updateUser({ first_name, last_name })
        .then(response => {
          this.setState({
            user: response.data,
            errors: [],
            saved: true
          });

          setTimeout(() => {
            this.setState({
              saved: false
            });
          }, 3000);
        });
    }
  }

  render () {
    const { user } = this.state;
    const styles = this.styles();

    return this.state.loading ? <Loader /> : (
      <div style={styles.component}>
        {this.state.saved ? <Saved /> : null}
        <h2>User Settings</h2>
        <Field label='First Name'>
          <Input
            invalid={this.state.errors.includes('first_name')}
            onChange={this._handleInputChange.bind(null, 'first_name')}
            required={true}
            value={user.first_name}
          />
        </Field>
        <Field label='Last Name'>
          <Input
            invalid={this.state.errors.includes('last_name')}
            onChange={this._handleInputChange.bind(null, 'last_name')}
            required={true}
            value={user.last_name}
          />
        </Field>
        <Field label='Email'>
          <Input
            readOnly={true}
            value={user.email}
          />
        </Field>

        <div style={{ marginTop: 30 }}>
          <Button onClick={this._save}>Save Changes</Button>
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        maxWidth: 480,
        padding: 30,
        position: 'relative'
      }
    };
  }
}

export default User;
