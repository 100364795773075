import React from 'react';
import { Route } from 'react-router-dom';
import _debounce from 'lodash/debounce';

import Button from 'shared/Button';

import List from './List';
import VersionForm from './VersionForm';
import Show from './Show';

import { Colors } from 'constants/Styles';

import Api from 'utils/Api';

import { FormsContext } from './Context';

class Forms extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      show_new_form: false,
      search_results: [],
      form_tags: [],
      product_groups: []
    };

    this.searchForms = _debounce(e => {
      const search = e.target.value;

      if (search.length > 2) {
        Api.loadForms({ search })
          .then(response => {
            this.setState({
              search_results: response.data
            });
          });
      } else {
        this.setState({
          search_results: []
        });
      }
    }, 400);
  }

  componentDidMount () {
    Api.loadProductGroups()
      .then(response => {
        this.setState({
          product_groups: response.data
        });
      });

    Api.loadFormTags()
      .then(response => {
        this.setState({
          form_tags: response.data
        });
      });
  }

  _search = (e) => {
    e.persist();
    this.searchForms(e);
  }

  _clearSearch = e => {
    this.setState({ search_results: [] });

    e.target.value = '';
  }

  _handleFormCreated = form => {
    this._toggleVersionForm(false);

    this.props.history.push(`/forms/${form.id}`);
  }

  _toggleVersionForm = show => {
    this.setState({ show_new_form: show });
  }

  _handleFormClick = form => {
    this.props.history.push(`/forms/${form.id}`);
  }

  _handleFormsClick = () => {
    this.props.history.push('/forms');
  }

  render () {
    const styles = this.styles();
    const { form_tags, product_groups } = this.state;

    return (
      <FormsContext.Provider value={{ form_tags, product_groups }}>
        <div className='flex w--100 h--100'>
          <div style={styles.content}>
            <div className='flex flex--space-between' style={styles.topBar}>
              <div className='flex flex--align-center'>
                <h2 style={{ margin: '0 40px 6px 0' }}>Forms</h2>
                <div className='flex flex--align-center' style={styles.breadcrumbs}>
                  <i className='mdi mdi-home' style={{ fontSize: 16 }} />
                  <i className='mdi mdi-chevron-right' style={{ color: '#ccc', margin: '0px 5px' }} />
                  <span onClick={this._handleFormsClick} style={styles.breadcrumb}>Forms</span>
                </div>
              </div>
              <div className='flex flex--align-center'>
                <div className='flex flex--align-center' style={styles.search}>
                  <input
                    onBlur={this._clearSearch}
                    onChange={this._search}
                    onFocus={this._search}
                    placeholder='Quick Search Forms...'
                    style={styles.searchInput} type='text'
                  />
                  <i className='mdi mdi-magnify' style={{ color: Colors.GRAY.hex, fontSize: 20 }} />
                  {this.state.search_results.length ? (
                    <div style={styles.searchResults}>
                      {this.state.search_results.map((form, i) => (
                        <div
                          key={i}
                          onMouseDown={this._handleFormClick.bind(null, form)}
                          style={styles.searchResultsItem}
                        >
                          <div style={{ fontWeight: 700, marginBottom: 4 }}>{form.name}</div>
                          <div style={{ fontSize: 12, color: '#777' }}>{form.number}</div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                <Button
                  onClick={this._toggleVersionForm.bind(null, true)}
                >
                  New Form <i className='mdi mdi-plus-circle-outline' style={{ fontSize: 18, marginLeft: 4 }} />
                </Button>
              </div>
            </div>

            <Route component={List} exact={true} path='/forms' />
            <Route path='/forms/:id' render={props => <Show {...props} key={props.match.params.id} />} />

            {this.state.show_new_form ? (
              <VersionForm
                onClose={this._toggleVersionForm.bind(null, false)}
                onSave={this._handleFormCreated}
                show={true}
              />
            ) : null}
          </div>
        </div>
      </FormsContext.Provider>
    );
  }

  styles = () => {
    return {
      content: {
        width: '100%',
        padding: '30px 40px'
      },
      topBar: {
        marginBottom: 40
      },
      breadcrumbs: {
        fontSize: 14,
        color: '#777'
      },
      breadcrumb: {
        cursor: 'pointer'
      },
      search: {
        height: 40,
        borderRadius: this.state.search_results.length ? '4px 4px 0 0' : 4,
        border: '1px solid #ccc',
        marginRight: 12,
        paddingRight: 12,
        width: 300,
        position: 'relative'
      },
      searchInput: {
        border: 'none',
        padding: '10px 8px 10px 16px',
        lineHeight: 1,
        borderRadius: 20,
        backgroundColor: 'transparent',
        width: '100%'
      },
      searchResults: {
        position: 'absolute',
        background: '#fff',
        left: -1,
        right: -1,
        bottom: 0,
        transform: 'translateY(100%)',
        border: '1px solid #ccc',
        borderRadius: '0px 0px 4px 4px',
        boxShadow: '0px 10px 20px -10px rgba(0,0,0,0.3)',
        maxHeight: 300,
        overflow: 'auto',
        zIndex: 5
      },
      searchResultsItem: {
        padding: 15,
        cursor: 'pointer',
        borderBottom: '1px solid #e5e5e5',
        marginBottom: -1
      },
      pill: {
        display: 'inline-block',
        height: 22,
        lineHeight: '22px',
        padding: '0px 12px',
        fontSize: 10,
        fontWeight: 700,
        color: '#fff',
        letterSpacing: 1,
        textTransform: 'uppercase',
        backgroundColor: '#444',
        borderRadius: 22
      }
    };
  }
}

export default Forms;
