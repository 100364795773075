module.exports = {
  BorderRadius: {
    DEFAULT: 4
  },
  FontFamilies: {
    MAIN: "'Roboto', Helvetica, Arial, sans-serif",
    ACCENT: "'Quicksand', 'Roboto', Helvetica, Arial, sans-serif"
  },
  FontSizes: {
    XSMALL: 10,
    SMALL: 12,
    REGULAR: 13,
    MEDIUM: 14,
    LARGE: 16,
    XLARGE: 20,
    XXLARGE: 30
  },
  FontWeights: {
    LIGHT: 300,
    REGULAR: 400,
    MEDIUM: 500,
    BOLD: 700
  },
  Colors: {
    GRAY: {
      hex: '#2F3B44',
      rgb: '47, 59, 68'
    },
    PRIMARY: {
      hex: '#52ADFF',
      rgb: '82, 173, 255'
    },
    SECONDARY: {
      hex: '#1DBC3E',
      rgb: '29, 188, 62'
    },
    BLUE: {
      hex: '#52ADFF',
      rgb: '82, 173, 255'
    },
    GREEN: {
      hex: '#1DBC3E',
      rgb: '29, 188, 62'
    },
    RED: {
      hex: '#DF4B4B',
      rgb: '223, 75, 75'
    }
  }
};
