import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Input from 'shared/Input';

import Api from 'utils/Api';

class Notes extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    onSave: PropTypes.func
  }

  constructor (props) {
    super(props);

    this.state = {
      notes: props.form.notes || '',
      saved: false
    };
  }

  _handleChange = (e) => {
    this.setState({
      notes: e.target.value
    });
  }

  _save = () => {
    Api.saveForm(this.props.form.id, { notes: this.state.notes })
      .then(response => {
        this.props.onSave(response.data);
      });
  }

  render () {
    const styles = this.styles();

    return (
      <div style={styles.component}>
        <Input
          onChange={this._handleChange}
          style={{ height: 300 }}
          type='textarea'
          value={this.state.notes}
        />
        <Button onClick={this._save} style={{ marginTop: 20 }}>Save Changes</Button>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        padding: '20px 0'
      }
    };
  }
}

export default Notes;
