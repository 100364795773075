import Axios from 'axios';
import Cookie from 'js-cookie';
import QueryString from 'query-string';

import { BASE_URL } from '../config';

const getAxios = () => {
  const auth = Cookie.get('dxp') ? JSON.parse(Cookie.get('dxp')) : {};
  const access_token = auth.access_token;
  const refresh_token = auth.refresh_token;

  const config = {
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'access-token': access_token,
      'refresh-token': refresh_token,
      'client': auth.client,
      'uid': auth.uid
    }
  };

  const axios = Axios.create(config);

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const original_request = error.config;

      if (error.response.status === 401) {
        return Axios.post(`${BASE_URL}auth/refresh`, {
          access_token,
          refresh_token
        })
          .then((response) => {
            original_request.headers['access-token'] = response.data.access_token;
            Cookie.set('dxp', { ...auth, access_token: response.data.access_token, refresh_token: response.data.refresh_token }, { expires: 14 });
            return axios(original_request);
          })
          .catch(() => {
            Cookie.remove('dxp', { domain: window.location.hostname });
            window.location = '/';
          });
      }
      return Promise.reject(error);
    }
  );

  return axios;
};

const Api = {
  login (params) {
    return getAxios().post('auth', params);
  },

  logout () {
    return getAxios().delete(`auth/${Math.floor(Date.now() / 1000)}`);
  },

  loadUser (id) {
    return getAxios().get(`carrier_admins/${id}`);
  },

  updateUser (params) {
    return getAxios().put('/carrier_admins/me', params);
  },

  loadCarrier () {
    return getAxios().get('/carrier');
  },

  updateCarrier (params) {
    return getAxios().put('/carrier', params);
  },

  loadCarrierAdmins () {
    return getAxios().get('/carrier_admins');
  },

  deleteCarrierAdmin (id) {
    return getAxios().delete(`/carrier_admins/${id}`);
  },

  createCarrierAdmin (params) {
    return getAxios().post('/carrier_admins', params);
  },

  updateCarrierAdmin (id, params) {
    return getAxios().put(`/carrier_admins/${id}`, params);
  },

  loadAllVendors () {
    return getAxios().get('vendors');
  },

  createVendor (params) {
    return getAxios().post('vendors', params);
  },

  loadVendorRelationships (filters = {}) {
    return getAxios().get(`vendor_carrier_relationships?${QueryString.stringify(filters)}`);
  },

  searchVendorRelationships (search_string) {
    return getAxios().get(`vendor_carrier_relationships?search=${search_string}`);
  },

  createVendorRelationship (params) {
    return getAxios().post('vendor_carrier_relationships', params);
  },

  loadVendorRelationship (id) {
    return getAxios().get(`vendor_carrier_relationships/${id}`);
  },

  updateVendorRelationship (id, params) {
    return getAxios().put(`vendor_carrier_relationships/${id}`, params);
  },

  loadProducts (params) {
    return getAxios().get('products', { params });
  },

  saveProduct (id, params) {
    return getAxios().put(`products/${id}`, params);
  },

  deleteProduct (id) {
    return getAxios().delete(`products/${id}`);
  },

  createProduct (params) {
    return getAxios().post('products', params);
  },

  loadProductGroups () {
    return getAxios().get('product_groups');
  },

  saveProductGroup (id, params) {
    return getAxios().put(`product_groups/${id}`, params);
  },

  deleteProductGroup (id) {
    return getAxios().delete(`product_groups/${id}`);
  },

  createProductGroup (params) {
    return getAxios().post('product_groups', params);
  },

  loadProductFeatures () {
    return getAxios().get('product_features');
  },

  loadForms (params) {
    return getAxios().get('forms', { params });
  },

  loadForm (id) {
    return getAxios().get(`forms/${id}`);
  },

  saveForm (id, params) {
    const axios = getAxios();
    const form_data = new FormData();

    Object.keys(params).forEach(key => {
      const value = params[key];

      if (value !== null) {
        if (Array.isArray(value)) {
          value.forEach(v => {
            form_data.append(`${key}[]`, v);
          });
        } else {
          form_data.append(key, value);
        }
      }
    });

    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';

    return axios.put(`forms/${id}`, form_data);
  },

  createForm (params) {
    const axios = getAxios();
    const form_data = new FormData();

    Object.keys(params).forEach(key => {
      const value = params[key];

      if (value) {
        if (Array.isArray(value)) {
          value.forEach(v => {
            form_data.append(`${key}[]`, v);
          });
        } else {
          form_data.append(key, value);
        }
      }
    });

    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';

    return axios.post('forms', form_data);
  },

  deleteForm (id) {
    return getAxios().delete(`/forms/${id}`);
  },

  loadFormTags () {
    return getAxios().get('form_tags');
  }
};

export default Api;
