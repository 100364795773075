import React from 'react';
import { Route } from 'react-router-dom';

import Loader from 'shared/Loader';
import Tabs from 'shared/Tabs';
import Toggle from 'shared/Toggle';

import Activity from './Activity';
import ApiSettings from './ApiSettings';
import Permissions from './Permissions';
import Contacts from './Contacts';

import Api from 'utils/Api';

class Show extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      vendor: {},
      loading: true
    };
  }

  componentDidMount () {
    this._loadVendor();
  }

  _loadVendor = () => {
    this.setState({ loading: true });

    Api.loadVendorRelationship(this.props.match.params.id)
      .then(response => {
        this.setState({
          vendor: response.data,
          loading: false
        });
      });
  }

  _toggleVendorStatus = () => {
    const params = { is_deactivated: !this.state.vendor.is_deactivated };

    this.setState({
      vendor: Object.assign({}, this.state.vendor, params)
    });

    Api.updateVendorRelationship(this.state.vendor.id, params)
      .then(() => {
        // no actio needed
      });
  }

  render () {
    const links = [
      { label: 'Activity', to: `/partners/details/${this.state.vendor.id}`, exact: true },
      { label: 'Permissions', to: `/partners/details/${this.state.vendor.id}/permissions` },
      { label: 'Contacts', to: `/partners/details/${this.state.vendor.id}/contacts` }
    ];
    const { permissions = {} } = this.state.vendor;
    const show_api_settings = permissions.li_quotes && permissions.li_quotes.length || permissions.ltc_quotes && permissions.ltc_quotes.length || permissions.forms && permissions.forms.length;

    if (show_api_settings) {
      links.push({ label: 'API Settings', to: `/partners/details/${this.state.vendor.id}/api-settings` });
    }

    return this.state.loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <div className='flex flex--space-between flex--align-center' style={{ margin: '40px 0 24px 0' }}>
          <h3 style={{ marginBottom: 0 }}>{this.state.vendor.name}</h3>
          <Toggle active={!this.state.vendor.is_deactivated} onClick={this._toggleVendorStatus} />
        </div>

        <Tabs links={links} />

        <Route exact={true} path='/partners/details/:id' render={routeProps => <Activity {...routeProps} vendor={this.state.vendor} />} />
        {show_api_settings ? (
          <Route path='/partners/details/:id/api-settings' render={routeProps => <ApiSettings {...routeProps} onChange={this._loadVendor} vendor={this.state.vendor} />} />
        ) : null}
        <Route path='/partners/details/:id/permissions' render={routeProps => <Permissions {...routeProps} onChange={this._loadVendor} vendor={this.state.vendor} />} />
        <Route path='/partners/details/:id/contacts' render={routeProps => <Contacts {...routeProps} onChange={this._loadVendor} vendor={this.state.vendor} />} />
      </React.Fragment>
    );
  }
}

export default Show;
