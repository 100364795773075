module.exports = {
  Colors: {
    GRAY: {
      hex: '#172027',
      rgb: '47, 59, 68'
    },
    BLUE: {
      hex: '#0497FF',
      rgb: '82, 173, 255'
    },
    GREEN: {
      hex: '#1DBC3E',
      rgb: '29, 188, 62'
    },
    RED: {
      hex: '#DF4B4B',
      rgb: '223, 75, 75'
    },
    TEXT: {
      hex: '#777'
    }
  },
  GradientBackground: {
    background: '#6cb520',
    background: '-moz-linear-gradient(-45deg, #6cb520 0%, #0497ff 100%)',
    background: '-webkit-linear-gradient(-45deg, #6cb520 0%,#0497ff 100%)',
    background: 'linear-gradient(135deg, #6cb520 0%,#0497ff 100%)',
    filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#6cb520", endColorstr="#0497ff",GradientType=1 )'
  }
};
