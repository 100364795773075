import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Field from 'shared/Field';
import Checklist from 'shared/Checklist';
import Saved from 'shared/Saved';
import Loader from 'shared/Loader';

import Api from 'utils/Api';

import { AuthContext } from 'src/AuthContext';

class Permissions extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    vendor: PropTypes.object
  }

  constructor (props) {
    super(props);

    this.state = {
      permissions: props.vendor.permissions || {},
      saved: false,
      loading: true,
      products: []
    };
  }

  componentDidMount () {
    Api.loadProducts()
      .then(response => {
        this.setState({
          loading: false,
          products: response.data
        });
      });
  }

  _handlePermissionsChange = (group, permissions) => {
    this.setState({
      permissions: Object.assign(this.state.permissions, { [group]: permissions })
    });
  }

  _save = () => {
    const { permissions } = this.state;
    const errors = [];

    if (errors.length) {
      this.setState({ errors });
    } else {
      Api.updateVendorRelationship(this.props.vendor.id, { permissions })
        .then(() => {
          this.props.onChange();
          this.setState({
            errors: [],
            saved: true
          });

          setTimeout(() => {
            this.setState({
              saved: false
            });
          }, 3000);
        });
    }
  }

  render () {
    const { loading, permissions, products, saved } = this.state;
    const styles = this.styles();
    const carrier_product_permissions = this.context.user.carrier_product_permissions;
    const life_quote_products = products.filter(p => {
      return ['Term', 'Term ROP', 'Whole Life', 'Final Expense', 'UL'].includes(p.product_type);
    });
    const ltc_quote_products = products.filter(p => p.product_type === 'Long Term Care');

    return loading ? <Loader /> : (
      <div style={styles.component}>
        {saved ? <Saved /> : null}

        {carrier_product_permissions.includes('life_quoting_api') ? (
          <Field
            label='Life Quotes API'
            labelDescription='Select which products you want this Partner to have access to. You can add and remove products at any time.'
            style={styles.field}
          >
            <Checklist
              columnCount={Math.ceil(life_quote_products.length / 10)}
              onChange={this._handlePermissionsChange.bind(null, 'li_quotes')}
              options={life_quote_products.map(p => ({ value: p.id, label: p.product_name }))}
              selected={permissions.li_quotes || []}
            />
          </Field>
        ) : null}

        {carrier_product_permissions.includes('ltc_quoting_api') ? (
          <Field
            label='LTC Quotes API'
            labelDescription='Select which products you want this Partner to have access to. You can add and remove products at any time.'
            style={styles.field}
          >
            <Checklist
              columnCount={Math.ceil(ltc_quote_products.length / 10)}
              onChange={this._handlePermissionsChange.bind(null, 'ltc_quotes')}
              options={ltc_quote_products.map(p => ({ value: p.id, label: p.product_name }))}
              selected={permissions.ltc_quotes || []}
            />
          </Field>
        ) : null}

        {carrier_product_permissions.includes('forms_api') ? (
          <Field
            label='Forms API'
            labelDescription='Select which products you want this Partner to have access to. You can add and remove products at any time.'
            style={styles.field}
          >
            <Checklist
              columnCount={Math.ceil(products.length / 10)}
              onChange={this._handlePermissionsChange.bind(null, 'forms')}
              options={products.map(p => ({ value: p.id, label: p.product_name }))}
              selected={permissions.forms || []}
            />
          </Field>
        ) : null}

        <Button onClick={this._save}>Save Changes</Button>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        padding: '40px 20px',
        position: 'relative'
      },
      field: {
        marginBottom: 40
      }
    };
  }
}

Permissions.contextType = AuthContext;

export default Permissions;
