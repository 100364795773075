import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Drawer from 'shared/Drawer';
import Field from 'shared/Field';
import Input from 'shared/Input';
import PowerSelect from 'shared/PowerSelect';
import Loader from 'shared/Loader';

import Api from 'utils/Api';

class AddVendor extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    show: PropTypes.bool
  }

  constructor (props) {
    super(props);

    this.state = {
      vendors: [],
      search_results: [],
      name: '',
      url: '',
      business_type: '',

      errors: [],
      loading: true
    };
  }

  componentDidMount () {
    Api.loadAllVendors()
      .then(response => {
        this.setState({
          vendors: response.data,
          search_results: response.data,
          loading: false
        });
      });
  }

  _handleSearchChange = (e) => {
    const name = e.target.value;

    this.setState({
      name,
      search_results: this.state.vendors.filter(v => {
        return v.name.toLowerCase().includes(name.toLowerCase()) || !name.length;
      })
    });
  }

  _handleInputChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  }

  _handleSelectChange = (name, selected) => {
    this.setState({
      [name]: selected
    });
  }

  _createVendorRelationship = (vendor) => {
    Api.createVendorRelationship({
      vendor_id: vendor.id
    })
      .then(response => {
        this.setState({
          name: '',
          url: '',
          business_type: '',
          errors: []
        });

        this.props.onSave(response.data);
      });
  }

  _createVendor = () => {
    const { name, url, business_type } = this.state;
    const errors = [];

    if (!name || name.length < 3) {
      errors.push('name');
    }

    if (!url || url.length < 5) {
      errors.push('url');
    }

    if (!business_type) {
      errors.push('business_type');
    }

    if (errors.length) {
      this.setState({ errors });
    } else {
      Api.createVendor({
        name,
        url,
        business_type: business_type.value
      })
        .then(response => {
          this._createVendorRelationship(response.data);
        });
    }
  }

  render () {
    return (
      <Drawer
        footer={this.state.search_results.length ? null : (
          <Button fullWidth={true} onClick={this._createVendor}>Create Partner</Button>
        )}
        onClose={this.props.onClose}
        show={this.props.show}
        title='Add Partner'
      >
        {this.state.loading ? <Loader /> : (
          <React.Fragment>
            <p style={{ marginBottom: 40 }}>Partners can belong to multiple carriers on the LifeDX. If you're working with a Partner that already has a LifeDX Partner account, then we'll simply add them to your Carrier account. Start by searching for an existing Partner. If we can't find a matching Partner, then you'll be displayed a form to create a new one.</p>
            <Field label='Name'>
              <Input
                invalid={this.state.errors.includes('name')}
                onChange={this._handleSearchChange}
                placeholder='Search by partner name...'
                required={true}
                value={this.state.name}
              />
            </Field>

            {this.state.search_results.length ? (
              <div>
                {this.state.search_results.map(v => {
                  return (
                    <div
                      key={v.id}
                      onMouseDown={this._createVendorRelationship.bind(null, v)}
                      style={{
                        padding: 15,
                        cursor: 'pointer',
                        borderBottom: '1px solid #e5e5e5',
                        marginBottom: -1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        <div style={{ fontWeight: 700, marginBottom: 4 }}>{v.name}</div>
                        <div style={{ fontSize: 12, color: '#777' }}>{v.url}</div>
                      </div>
                      <Button small={true}>Add Partner</Button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <React.Fragment>
                <Field label='Website URL'>
                  <Input
                    invalid={this.state.errors.includes('url')}
                    onChange={this._handleInputChange.bind(null, 'url')}
                    required={true}
                    value={this.state.url}
                  />
                </Field>
                <Field label='Business Type'>
                  <PowerSelect
                    invalid={this.state.errors.includes('business_type')}
                    onChange={this._handleSelectChange.bind(null, 'business_type')}
                    options={[
                      {
                        value: 'Comparison Aggregator',
                        label: 'Comparison Aggregator'
                      },
                      {
                        value: 'Digital Marketer',
                        label: 'Digital Marketer'
                      },
                      {
                        value: 'IMO/BGA',
                        label: 'IMO/BGA'
                      },
                      {
                        value: 'Captive Agency',
                        label: 'Captive Agency'
                      },
                      {
                        value: 'Carrier Tool',
                        label: 'Carrier Tool'
                      },
                      {
                        value: 'Other',
                        label: 'Other'
                      }
                    ]}
                    placeholder='-- Select One --'
                    required={true}
                    style={{ marginTop: 10 }}
                    value={this.state.business_type}
                  />
                </Field>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Drawer>
    );
  }
}

export default AddVendor;
