import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Select from 'shared/Select';
import Saved from 'shared/Saved';

import { STATES } from 'constants/Form';

import Api from 'utils/Api';

class General extends React.Component {
  static propTypes = {
    carrier: PropTypes.object
  }

  constructor (props) {
    super(props);

    this.state = {
      name: props.carrier.name || '',
      street1: props.carrier.street1 || '',
      street2: props.carrier.street2 || '',
      city: props.carrier.city || '',
      state: props.carrier.state || '',
      zipcode: props.carrier.zipcode || '',
      errors: []
    };
  }

  _handleInputChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  }

  _save = () => {
    const { errors, ...carrier } = this.state;

    if (!carrier.name || carrier.name.length < 3) {
      errors.push('name');
    }

    if (errors.length) {
      this.setState({ errors });
    } else {
      Api.updateCarrier(carrier)
        .then(() => {
          this.setState({
            errors: [],
            saved: true
          });

          setTimeout(() => {
            this.setState({
              saved: false
            });
          }, 3000);
        });
    }
  }

  render () {
    const styles = this.styles();

    return (
      <div style={styles.component}>
        {this.state.saved ? <Saved /> : null}

        <Field label='Name'>
          <Input
            invalid={this.state.errors.includes('name')}
            onChange={this._handleInputChange.bind(null, 'name')}
            required={true}
            value={this.state.name}
          />
        </Field>
        <Field label='Street'>
          <Input
            invalid={this.state.errors.includes('street1')}
            onChange={this._handleInputChange.bind(null, 'street1')}
            required={true}
            value={this.state.street1}
          />
        </Field>
        <Field label='' style={{ marginTop: -15 }}>
          <Input
            invalid={this.state.errors.includes('street2')}
            onChange={this._handleInputChange.bind(null, 'street2')}
            required={true}
            value={this.state.street2}
          />
        </Field>
        <Field label='City'>
          <Input
            invalid={this.state.errors.includes('city')}
            onChange={this._handleInputChange.bind(null, 'city')}
            required={true}
            value={this.state.city}
          />
        </Field>
        <Field label='State'>
          <Select
            invalid={this.state.errors.includes('state')}
            onChange={this._handleInputChange.bind(null, 'state')}
            options={STATES}
            required={true}
            value={this.state.state}
          />
        </Field>
        <Field label='Zip Code'>
          <Input
            invalid={this.state.errors.includes('zipcode')}
            onChange={this._handleInputChange.bind(null, 'zipcode')}
            required={true}
            value={this.state.zipcode}
          />
        </Field>

        <div style={{ marginTop: 30 }}>
          <Button onClick={this._save}>Save Changes</Button>
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        padding: '40px 20px',
        maxWidth: 480,
        position: 'relative'
      }
    };
  }
}

export default General;
