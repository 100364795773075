import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Drawer from 'shared/Drawer';
import Table from 'shared/Table';

import EditContact from './EditContact';

import { Colors } from 'constants/Styles';

import Api from 'utils/Api';

class Contacts extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    vendor: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props);

    this.state = {
      contacts: props.vendor.contacts || [],
      contact: null,
      showEditContact: false
    };
  }

  _showEditContact = contact => {
    this.setState({
      contact,
      showEditContact: true
    });
  }

  _dismissNewContact = () => {
    this.setState({
      showEditContact: false
    });
  }

  _save = contact => {
    let { contacts } = this.state;

    if (contact.id) {
      contacts = contacts.map(c => (c.id === contact.id ? contact : c));
    } else {
      contacts.push(contact);
    }

    Api.updateVendorRelationship(this.props.vendor.id, { contacts })
      .then(response => {
        this.setState({
          contacts: response.data.contacts,
          contact: false,
          showEditContact: false
        });

        this.props.onChange();
      });
  }

  _delete = contact => {
    const contacts = this.state.contacts.map(c => {
      if (c.id === contact.id) {
        c._destroy = true;
      }

      return c;
    });

    Api.updateVendorRelationship(this.props.vendor.id, { contacts })
      .then(response => {
        this.setState({
          contacts: response.data.contacts,
          contact: null,
          showEditContact: false
        });

        this.props.onChange();
      });
  }

  render () {
    const styles = this.styles();

    return (
      <div style={styles.component}>
        <Drawer onClose={this._dismissNewContact} show={this.state.showEditContact} title={`${this.state.contact ? 'Edit' : 'New'} Contact`}>
          {this.state.showEditContact ? (
            <EditContact
              contact={this.state.contact}
              onDelete={this._delete}
              onSave={this._save}
            />
          ) : null}
        </Drawer>

        <Table
          columns={[
            {
              id: 'name',
              is_sortable: true,
              render: c => <div style={styles.name}>{c.first_name} {c.last_name}</div>
            },
            {
              id: 'email',
              is_sortable: true,
              render: c => <div style={styles.email}>{c.email}</div>
            },
            {
              id: 'phone'
            },
            {
              id: 'title',
              is_sortable: true
            }
          ]}
          data={this.state.contacts}
          defaultSortColumn={{ id: 'name' }}
          hideHeader={true}
          onRowClick={this._showEditContact}
        />

        <Button onClick={this._showEditContact.bind(null, null)} style={{ marginTop: 20 }}>
          Add Contact <i className='mdi mdi-plus-circle-outline' style={{ fontSize: 18, marginLeft: 4 }} />
        </Button>
      </div>
    );
  }

  styles = () => {
    return {
      component: {},
      name: {
        fontWeight: 700
      },
      email: {
        color: Colors.BLUE.hex,
        fontSize: 14,
        textDecoration: 'none'
      }
    };
  }
}

export default Contacts;
