import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'bitts';

const Input = (props) => {
  const styles = {
    input: Object.assign({}, {
      marginTop: 6,
      width: '100%',
      WebkitAppearance: 'none',
      boxShadow: 'none',
      outline: 'none',
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '40px',
      height: 40,
      padding: 10,
      background: '#fff'
    }, props.style)
  };

  return (
    <Field
      disabled={props.readOnly}
      format={props.format}
      invalid={props.invalid}
      maxLength={props.maxLength}
      min={props.min}
      name={props.name}
      onBlur={props.onBlur}
      onChange={props.onChange}
      placeholder={props.placeholder}
      required={props.required}
      step={props.step}
      style={styles.input}
      type={props.type}
      value={props.value}
    />
  );
};

Input.propTypes = {
  format: PropTypes.oneOf(['phone', 'currency', 'currency_decimal', 'ssn']),
  invalid: PropTypes.bool,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  step: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'password', 'phone', 'email', 'textarea']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default Input;

