import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Field from 'shared/Field';
import Input from 'shared/Input';

import { _validateEmail } from 'constants/Form';

class EditContact extends React.Component {
  static propTypes = {
    contact: PropTypes.object,
    onDelete: PropTypes.func,
    onSave: PropTypes.func
  }

  constructor (props) {
    super(props);

    this.state = {
      contact: props.contact || {},
      showConfirmDelete: false,
      errors: []
    };
  }

  _delete = () => {
    this.setState({
      showConfirmDelete: true
    });
  }

  _dismissDelete = () => {
    this.setState({
      showConfirmDelete: false
    });
  }

  _handleInputChange = (name, e) => {
    this.setState({
      contact: Object.assign({}, this.state.contact, {
        [name]: e.target.value
      })
    });
  }

  _save = () => {
    const { contact } = this.state;
    const errors = [];

    if (!contact.first_name || contact.first_name.length < 3) {
      errors.push('first_name');
    }

    if (!contact.last_name || contact.last_name.length < 3) {
      errors.push('last_name');
    }

    if (!_validateEmail(contact.email)) {
      errors.push('email');
    }

    if (errors.length) {
      this.setState({ errors });
    } else {
      this.props.onSave(contact);
    }
  }

  render () {
    const { contact } = this.state;
    const styles = this.styles();

    return (
      <div style={styles.component}>
        {this.state.showConfirmDelete ? (
          <div style={styles.confirm}>
            <div style={styles.confirmBody}>
              Are you sure you want to delete <span style={{ fontWeight: 700 }}>{this.state.contact.first_name} {this.state.contact.last_name}</span>

              <div style={styles.confirmButtons}>
                <Button onClick={this._dismissDelete}>Nevermind</Button>
                <Button color='RED' onClick={this.props.onDelete.bind(null, this.props.contact)} style={{ marginLeft: 6 }}>Yes, Delete Them</Button>
              </div>
            </div>
          </div>
        ) : null}

        <div style={styles.form}>
          <Field label='First Name'>
            <Input
              invalid={this.state.errors.includes('first_name')}
              onChange={this._handleInputChange.bind(null, 'first_name')}
              required={true}
              value={contact.first_name}
            />
          </Field>

          <Field label='Last Name'>
            <Input
              invalid={this.state.errors.includes('last_name')}
              onChange={this._handleInputChange.bind(null, 'last_name')}
              required={true}
              value={contact.last_name}
            />
          </Field>

          <Field label='Email'>
            <Input
              invalid={this.state.errors.includes('email')}
              onChange={this._handleInputChange.bind(null, 'email')}
              required={true}
              type='email'
              value={contact.email}
            />
          </Field>

          <Field label='Phone'>
            <Input
              format='phone'
              invalid={this.state.errors.includes('phone')}
              onChange={this._handleInputChange.bind(null, 'phone')}
              type='phone'
              value={contact.phone}
            />
          </Field>

          <Field label='Title'>
            <Input
              invalid={this.state.errors.includes('title')}
              onChange={this._handleInputChange.bind(null, 'title')}
              value={contact.title}
            />
          </Field>
        </div>

        <div style={styles.actions}>
          {contact.id ? (
            <React.Fragment>
              <Button color='RED' onClick={this._delete}>Delete</Button>
              <Button onClick={this._save} style={{ flex: 1, marginLeft: 10 }}>Save</Button>
            </React.Fragment>
          ) : (
            <Button onClick={this._save} style={{ width: '100%' }}>Create Contact</Button>
          )}
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      },
      actions: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        padding: 30,
        background: '#fff'
      },
      confirm: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(0,0,0,0.6)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        zIndex: 10
      },
      confirmBody: {
        background: '#fff',
        padding: 40,
        width: 320,
        borderRadius: 8,
        fontSize: 16,
        lineHeight: '1.4em',
        marginBottom: 100
      },
      confirmButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20
      }
    };
  }
}

export default EditContact;
