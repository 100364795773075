import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Field from 'shared/Field';
import Input from 'shared/Input';
import PowerSelect from 'shared/PowerSelect';
import Saved from 'shared/Saved';

import Api from 'utils/Api';

class ApiSettings extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    vendor: PropTypes.object
  }

  constructor (props) {
    super(props);

    this.state = {
      whitelisted_hosts: props.vendor.whitelisted_hosts ? props.vendor.whitelisted_hosts.map(w => ({ label: w, value: w })) : [],
      errors: [],
      saved: false
    };
  }

  _handleHostsChange = (selected) => {
    this.setState({
      whitelisted_hosts: selected || []
    });
  }

  _save = () => {
    const { whitelisted_hosts = [] } = this.state;
    const errors = [];

    if (errors.length) {
      this.setState({ errors });
    } else {
      Api.updateVendorRelationship(this.props.vendor.id, {
        whitelisted_hosts: whitelisted_hosts.map(w => w.value)
      })
        .then(() => {
          this.props.onChange();
          this.setState({
            errors: [],
            saved: true
          });

          setTimeout(() => {
            this.setState({
              saved: false
            });
          }, 3000);
        });
    }
  }

  render () {
    const { vendor } = this.props;
    const { whitelisted_hosts } = this.state;
    const styles = this.styles();

    return (
      <div style={styles.component}>
        {this.state.saved ? <Saved /> : null}
        <Field
          label='Partner Token'
          labelDescription='This identifies the partner when making requests to the API. It must be passed as a `VENDOR-TOKEN` header.'
          style={styles.field}
        >
          <Input readOnly={true} type='text' value={vendor.vendor_id} />
        </Field>
        <Field
          label='Carrier Token'
          labelDescription='This token is unique to your relationship with the partner. The partner will pass this token as a request parameter to access your data.'
          style={styles.field}
        >
          <Input readOnly={true} type='text' value={vendor.api_token} />
        </Field>
        <Field
          label='Whitelisted Hosts'
          labelDescription="Enter the domains and/or IP addresses that are allowed access to the API endpoints for this Partner. This helps to ensure that the Partner's credentials aren’t used by unauthorized parties."
          style={styles.field}
        >
          <PowerSelect
            creatable={true}
            isMulti={true}
            noOptionsMessage='Start typing...'
            onChange={this._handleHostsChange}
            placeholder='Enter domains and ip addresses...'
            style={{ marginTop: 10 }}
            value={whitelisted_hosts}
          />
        </Field>

        <Button onClick={this._save}>Save Changes</Button>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        padding: '40px 20px',
        maxWidth: 480,
        position: 'relative'
      },
      field: {
        marginBottom: 40
      }
    };
  }
}

export default ApiSettings;
