import React from 'react';

import { Colors } from 'constants/Styles';

const Saved = () => {
  const styles = {
    saved: {
      position: 'absolute',
      padding: 40,
      background: '#fff',
      zIndex: 10,
      borderRadius: 8,
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 0px 0px 6px rgb(29, 188, 62, 0.6), 0px 5px 30px 0px rgba(0, 0, 0, 0.4)',
      textAlign: 'center'
    },
    savedIcon: {
      fontSize: 40,
      color: Colors.GREEN.hex
    },
    savedText: {
      textTransform: 'uppercase',
      color: '#777',
      fontSize: 16,
      letterSpacing: 1,
      marginTop: 6
    }
  };

  return (
    <div style={styles.saved}>
      <i className='mdi mdi-check-outline' style={styles.savedIcon} />
      <div style={styles.savedText}>SAVED</div>
    </div>
  );
};

export default Saved;

