import React from 'react';
import { Route } from 'react-router-dom';

import Loader from 'shared/Loader';
import Tabs from 'shared/Tabs';

import General from './General';
import Users from './Users';

import Api from 'utils/Api';

import { AuthContext } from 'src/AuthContext';

class Carrier extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      carrier: {},
      loading: true
    };
  }

  componentDidMount () {
    Api.loadCarrier()
      .then(response => {
        this.setState({
          carrier: response.data,
          loading: false
        });
      });
  }

  render () {
    const styles = this.styles();
    const links = [];
    const routes = [];
    const { user } = this.context;

    if (user.permissions.includes('carrier')) {
      links.push({ label: 'General Information', to: '/carrier', exact: true });
      routes.push(<Route exact={true} path='/carrier' render={routeProps => <General {...routeProps} carrier={this.state.carrier} />} />);
    }

    if (user.permissions.includes('users')) {
      links.push({ label: 'Users', to: '/carrier/admins' });
      routes.push(<Route path='/carrier/admins' render={routeProps => <Users {...routeProps} carrier={this.state.carrier} />} />);
    }

    if (!user.permissions.includes('carrier')) {
      window.location = '/#/carrier/admins';
    }

    if (!user.permissions.includes('users')) {
      window.location = '/';
    }

    return this.state.loading ? <Loader /> : (
      <div style={styles.component}>
        <h2>{this.state.carrier.name}</h2>
        <Tabs
          links={links}
        />

        {routes}
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        padding: 30
      }
    };
  }
}

Carrier.contextType = AuthContext;

export default Carrier;
