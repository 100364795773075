import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import _debounce from 'lodash/debounce';

import Input from 'shared/Input';
import Loader from 'shared/Loader';
import Select from 'shared/Select';
import Table from 'shared/Table';
import ZeroState from 'shared/ZeroState';

import Api from 'utils/Api';

import { STATES } from 'constants/Form';
import { Colors } from 'constants/Styles';

import { FormsContext } from './Context';

class List extends React.Component {
  static propTypes = {
    filters: PropTypes.object,
    onFormClick: PropTypes.func
  }

  constructor (props) {
    super(props);

    this.state = {
      forms: [],
      pagination: {
        first_page: true,
        last_page: false,
        next_page: 2,
        offset: 0,
        out_of_bounds: false,
        previous_page: null,
        total: 20,
        total_pages: 1
      },
      params: {
        form_tag_id: '',
        page: 1,
        product_group_id: '',
        search: '',
        state: ''
      },
      loading: true
    };

    this.search = _debounce(params => {
      this._loadForms(params);
    }, 600);
  }

  componentDidMount () {
    this._loadForms({ page: 1 });
  }

  _loadForms = (params) => {
    this.setState({
      loading: true
    });

    Api.loadForms(params)
      .then(response => {
        this.setState({
          forms: response.data,
          pagination: JSON.parse(response.headers['x-pagination']),
          loading: false
        });
      });
  }

  _handlePageClick = (page) => {
    const params = Object.assign({}, this.state.params, { page });

    this.setState({ params });
    this._loadForms(params);
  }

  _handleSelectChange = (name, e) => {
    const params = Object.assign({}, this.state.params, {
      [name]: e.target.value,
      page: 1
    });

    this.setState({ params });
    this._loadForms(params);
  }

  _search = e => {
    const params = Object.assign({}, this.state.params, {
      search: e.target.value,
      page: 1
    });

    this.setState({ params });
    this.search(params);
  }

  _handleFormClick = form => {
    this.props.history.push(`/forms/${form.id}`);
  }

  _openInNewTab = (form, e) => {
    e.stopPropagation();
    window.open(`/#/forms/${form.id}`);
  }

  render () {
    const styles = this.styles();
    const { forms, loading, pagination, params } = this.state;
    const { product_groups, form_tags } = this.context;

    return (
      <div style={styles.component}>
        <div style={styles.filters}>
          <Input
            onChange={this._search}
            placeholder='Search by name or number...'
            style={styles.filter}
            value={params.search}
          />
          <Select
            onChange={this._handleSelectChange.bind(null, 'product_group_id')}
            options={[{ value: '', label: 'All Product Groups' }].concat(product_groups.map(pg => ({ value: pg.id, label: pg.group_name })))}
            style={styles.filter}
            value={params.product_group_id}
          />
          <Select
            onChange={this._handleSelectChange.bind(null, 'state')}
            options={[{ value: '', label: 'All States' }].concat(STATES)}
            style={styles.filter}
            value={params.state}
          />
          <Select
            onChange={this._handleSelectChange.bind(null, 'form_tag_id')}
            options={[{ value: '', label: 'All Tags' }].concat(form_tags.map(ft => ({ value: ft.id, label: ft.name })))}
            style={styles.filter}
            value={params.form_tag_id}
          />
        </div>

        {loading ? <div style={styles.loader}><Loader /></div> : (
          <React.Fragment>
            {forms.length ? (
              <Table
                columns={[
                  {
                    id: 'id',
                    label: 'ID'
                  },
                  {
                    id: 'name',
                    label: 'Name'
                  },
                  {
                    id: 'number',
                    label: 'Number'
                  },
                  {
                    id: 'publish_date',
                    label: 'Publish Date',
                    render: form => {
                      return form.publish_date ? Moment(form.publish_date).format('MMM Do, YYYY') : '--';
                    }
                  },
                  {
                    id: 'actions',
                    label: '',
                    render: form => {
                      return <i className='mdi mdi-open-in-new' onClick={this._openInNewTab.bind(null, form)} style={styles.open_in_new_tab} />;
                    }
                  }
                ]}
                data={forms}
                onPageClick={this._handlePageClick}
                onRowClick={this._handleFormClick}
                page={params.page}
                pagination={pagination}
              />
            ) : (
              <ZeroState
                description='Try adjusting your search criteria above.'
                icon='file-multiple'
                title='No Forms Found'
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        position: 'relative'
      },
      loader: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.90)'
      },
      filters: {
        display: 'flex',
        marginBottom: 20
      },
      filter: {
        width: 200,
        marginRight: 10
      },
      open_in_new_tab: {
        cursor: 'pointer',
        color: Colors.BLUE.hex,
        fontSize: 20,
        width: '100%',
        textAlign: 'right'
      }
    };
  }
}

List.contextType = FormsContext;

export default List;
