import React from 'react';
import PropTypes from 'prop-types';

import { BorderRadius, Colors, FontSizes } from './Theme';

const Alert = ({ children, type }) => {
  const styles = {
    component: {
      justifyContent: 'center',
      position: 'relative'
    },
    alert: {
      margin: '0 0 20px 0',
      borderRadius: BorderRadius.DEFAULT,
      padding: 15,
      fontSize: FontSizes.MEDIUM,
      lineHeight: '1.6em',
      position: 'relative'
    },
    error: {
      backgroundColor: `${Colors.RED.hex}30`,
      border: `1px solid ${Colors.RED.hex}`,
      color: Colors.RED.hex
    },
    success: {
      backgroundColor: `${Colors.GREEN.hex}30`,
      border: `1px solid ${Colors.GREEN.hex}`,
      color: Colors.GREEN.hex
    },
    info: {
      backgroundColor: `${Colors.BLUE.hex}30`,
      border: `1px solid ${Colors.BLUE.hex}`,
      color: Colors.BLUE.hex
    }
  };

  return (
    <div style={styles.component}>
      <div style={Object.assign({}, styles.alert, styles[type])}>{children}</div>
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.string
};

export default Alert;
