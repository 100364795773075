import React from 'react';
import PropTypes from 'prop-types';

const Drawer = props => {
  const styles = {
    scrim: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0,0,0,0.5)',
      zIndex: props.show ? 10 : -1,
      opacity: props.show ? 1 : 0,
      transition: 'all .3s'
    },
    content: {
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      background: '#fff',
      zIndex: 10,
      width: 500,
      boxShadow: '0px -2px 20px 15px rgba(0,0,0,0.1)',
      transition: 'all .3s',
      transform: `translateX(${props.show ? '0%' : '-120%'})`
    },
    innerContent: {
      overflow: 'auto',
      padding: 30,
      flex: 1
    },
    footer: {
      padding: '20px 30px'
    },
    close: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 29,
      right: -50,
      background: '#fff',
      width: 50,
      height: 52,
      borderRadius: '0 26px 26px 0',
      paddingRight: 4,
      cursor: 'pointer',
      flexShrink: 0
    }
  };

  return (
    <React.Fragment>
      <div onClick={props.onClose} style={styles.scrim} />
      <div className='flex flex--column flex--space-between' style={styles.content}>
        <div onClick={props.onClose} style={styles.close}>
          <i className='mdi mdi-close' style={{ fontSize: 24 }} />
        </div>
        <div style={styles.innerContent}>
          {props.title ? <h3>{props.title}</h3> : null}

          {props.children}
        </div>
        <div style={styles.footer}>
          {props.footer}
        </div>
      </div>
    </React.Fragment>
  );
};

Drawer.propTypes = {
  footer: PropTypes.node,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string
};

export default Drawer;
