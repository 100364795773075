import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Drawer from 'shared/Drawer';
import Field from 'shared/Field';
import Input from 'shared/Input';

import { Colors } from 'constants/Styles';

import Api from 'utils/Api';

class ProductGroupForm extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
    productGroup: PropTypes.object,
    show: PropTypes.bool
  }

  constructor (props) {
    super(props);

    const product_group = props.productGroup || {};

    this.state = {
      group_name: product_group.group_name || '',
      show_delete_warning: false,
      errors: []
    };
  }

  _handleInputChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  }

  _save = () => {
    const { group_name } = this.state;
    const errors = [];

    if (!group_name || group_name.length < 3) {
      errors.push('product_name');
    }

    if (errors.length) {
      this.setState({ errors });
    } else if (this.props.productGroup) {
      Api.saveProductGroup(this.props.productGroup.id, { group_name })
        .then(response => {
          this.props.onSave(response.data);
        });
    } else {
      Api.createProductGroup({ group_name })
        .then(response => {
          this.props.onSave(response.data);
        });
    }
  }

  _toggleDeleteWarning = () => {
    this.setState({
      show_delete_warning: !this.state.show_delete_warning
    });
  }

  _confirmDelete = () => {
    Api.deleteProductGroup(this.props.productGroup.id)
      .then(response => {
        this.props.onDelete(response.data);
      });
  }

  _renderTitle = () => {
    if (this.state.show_delete_warning) {
      return 'Caution!';
    } else {
      return this.props.productGroup ? 'Edit Product Group' : 'New Product Group';
    }
  }

  _renderFooter = () => {
    if (this.state.show_delete_warning) {
      return (
        <React.Fragment>
          <Button color='RED' fullWidth={true} onClick={this._confirmDelete}>Confirm Deletion</Button>
          <div onClick={this._toggleDeleteWarning} style={{ color: Colors.BLUE.hex, fontWeight: 600, cursor: 'pointer', marginTop: 15, textAlign: 'center' }}>Nevermind, I made a mistake</div>
        </React.Fragment>
      );
    } else {
      return this.props.productGroup ? (
        <React.Fragment>
          <Button fullWidth={true} onClick={this._save}>Save</Button>
          <div
            onClick={this._toggleDeleteWarning}
            style={{ color: Colors.RED.hex, fontWeight: 600, cursor: 'pointer', marginTop: 15, textAlign: 'center' }}
          >
            Delete
          </div>
        </React.Fragment>
      ) : (
        <Button fullWidth={true} onClick={this._save}>Create</Button>
      );
    }
  }

  render () {
    return (
      <Drawer
        footer={this._renderFooter()}
        onClose={this.props.onClose}
        show={this.props.show}
        title={this._renderTitle()}
      >
        {this.state.show_delete_warning ? (
          <React.Fragment>
            <p>Deleting this product group will delete it's related products, quote data, and form data! This means, they will no longer be available to partners. Proceed with caution.</p>
          </React.Fragment>
        ) : (
          <Field label='Name'>
            <Input
              invalid={this.state.errors.includes('group_name')}
              onChange={this._handleInputChange.bind(null, 'group_name')}
              required={true}
              value={this.state.group_name}
            />
          </Field>
        )}
      </Drawer>
    );
  }
}

export default ProductGroupForm;
