import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import Table from 'shared/Table';

import { Colors } from 'constants/Styles';

class Versions extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    onVersionClick: PropTypes.func
  }

  render () {
    const styles = this.styles();

    return (
      <div style={styles.component}>
        <Table
          columns={[
            {
              id: 'id',
              label: 'ID'
            },
            {
              id: 'publish_date',
              label: 'Publish Date',
              render: version => {
                return Moment(version.publish_date).format('MMM Do, YYYY');
              }
            },
            {
              id: 'status',
              label: 'Status',
              render: version => {
                const publish_date = Moment(version.publish_date);
                const today = Moment().endOf('day');

                if (publish_date.isAfter(today)) {
                  return <div style={Object.assign({}, styles.pill, { background: Colors.BLUE.hex })}>Scheduled</div>;
                } else if (version.id === this.props.form.version_id) {
                  return <div style={Object.assign({}, styles.pill, { background: Colors.GREEN.hex })}>Live</div>;
                } else {
                  return null;
                }
              }
            }
          ]}
          data={this.props.form.form_versions}
          hideHeader={true}
          onRowClick={this.props.onVersionClick}
        />
      </div>
    );
  }

  styles = () => {
    return {
      pill: {
        height: 30,
        padding: '0 20px',
        display: 'inline-block',
        lineHeight: '30px',
        textAlign: 'center',
        borderRadius: 15,
        backgroundColor: '#444',
        color: '#fff',
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: 1,
        fontSize: 12,
        float: 'right'
      }
    };
  }
}

export default Versions;
