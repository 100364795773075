import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Cookie from 'js-cookie';

import Analytics from './analytics/Index';
import Carrier from './carrier/Index';
import Forms from './forms/Index';
import Etickets from './etickets/Index';
import Rates from './rates/Index';
import Products from './products/Index';
import User from './user/Index';
import Vendors from './vendors/Index';

import { Colors, GradientBackground } from 'constants/Styles';

import Api from 'utils/Api';

import { AuthContext } from 'src/AuthContext';

class Authenticated extends React.Component {
  _navigate = (path) => {
    this.props.history.push(path);
  }

  _logout = () => {
    Api.logout();

    Cookie.remove('dxp', { domain: window.location.hostname });

    this.props.history.push('/');
  }

  render () {
    const styles = this.styles();
    const menu_items = [];
    const current_user = this.context.user;
    const { permissions = [], carrier_product_permissions = [] } = current_user;

    let redirect_route = 'user';

    if (permissions.includes('users') || permissions.includes('carrier')) {
      menu_items.push({ icon: 'settings', path: 'carrier' });
      redirect_route = 'carrier';
    }

    if (carrier_product_permissions.includes('form_management') && permissions.includes('products')) {
      menu_items.push({ icon: 'package', path: 'products' });
      redirect_route = 'products';
    }

    if (carrier_product_permissions.includes('analytics')) {
      menu_items.push({ icon: 'chart-areaspline', path: 'analytics' });
      redirect_route = 'analytics';
    }

    if (carrier_product_permissions.includes('embeddable_etickets')) {
      menu_items.push({ icon: 'file-document-box-multiple', path: 'etickets' });
      redirect_route = 'etickets';
    }

    if (carrier_product_permissions.includes('form_management') && permissions.includes('forms')) {
      menu_items.push({ icon: 'file-multiple', path: 'forms' });
      redirect_route = 'forms';
    }

    if (carrier_product_permissions.includes('vendor_management') && permissions.includes('vendors')) {
      menu_items.push({ icon: 'domain', path: 'partners' });
      redirect_route = 'partners';
    }

    return (
      <div className='flex h--100'>
        <div style={styles.appMenuContainer}>
          <div style={styles.appMenu}>
            <div onClick={this._navigate.bind(null, '/user')} style={Object.assign({}, styles.avatar, this.props.location.pathname.includes('user') && styles.activeAvatar)}>
              <i className='mdi mdi-account' style={{ fontSize: 24 }} />
            </div>
            <div style={styles.menu}>
              {menu_items.reverse().map(menuItem => (
                <i
                  className={`mdi mdi-${menuItem.icon}`}
                  key={menuItem.path}
                  onClick={this._navigate.bind(null, `/${menuItem.path}`)}
                  style={Object.assign(
                    {},
                    styles.icon,
                    this.props.location.pathname.includes(menuItem.path) && styles.activeIcon
                  )}
                />
              ))}
            </div>

          </div>
          <div style={styles.logout}>
            <i className='mdi mdi-logout-variant' onClick={this._logout} style={Object.assign({}, styles.icon, styles.activeIcon)} />
          </div>
        </div>
        <div style={Object.assign({ width: 4 }, GradientBackground)} />
        <div className='w--100'>
          <Switch>
            <Redirect exact={true} from='/' to={`/${redirect_route}`} />

            {carrier_product_permissions.includes('vendor_management') && permissions.includes('vendors') ? <Route component={Vendors} path='/partners' /> : null}
            {permissions.includes('rates') ? <Route component={Rates} path='/rates-management' /> : null}
            {carrier_product_permissions.includes('form_management') && permissions.includes('forms') ? <Route component={Forms} path='/forms' /> : null}
            {carrier_product_permissions.includes('embeddable_etickets') ? <Route component={Etickets} path='/etickets' /> : null}
            {carrier_product_permissions.includes('analytics') ? <Route component={Analytics} path='/analytics' /> : null}
            {carrier_product_permissions.includes('form_management') && permissions.includes('products') ? <Route component={Products} path='/products' /> : null}
            {permissions.includes('carrier') || permissions.includes('users') ? <Route component={Carrier} path='/carrier' /> : null}

            <Route component={User} path='/user' />
          </Switch>
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      appMenuContainer: {
        width: 82,
        flexShrink: 0,
        backgroundColor: Colors.GRAY.hex
      },
      appMenu: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: 82,
        padding: 16
      },
      avatar: {
        width: 50,
        height: 50,
        borderRadius: '100%',
        backgroundColor: 'transparent',
        border: '1px solid #fff',
        textAlign: 'center',
        lineHeight: '48px',
        fontWeight: 700,
        color: '#fff',
        cursor: 'pointer'
      },
      activeAvatar: {
        backgroundColor: '#fff',
        color: Colors.GRAY.hex
      },
      menu: {
        textAlign: 'center',
        marginTop: 56
      },
      logout: {
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: 82
      },
      icon: {
        fontSize: 24,
        color: '#fff',
        display: 'block',
        margin: '32px 0px',
        opacity: 0.3,
        cursor: 'pointer'
      },
      activeIcon: {
        opacity: 1
      }
    };
  }
}

Authenticated.contextType = AuthContext;

export default Authenticated;
