import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import Button from 'shared/Button';
import Checklist from 'shared/Checklist';
import DatePicker from 'shared/DatePicker';
import Drawer from 'shared/Drawer';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Loader from 'shared/Loader';

import Api from 'utils/Api';

import { STATES } from 'constants/Form';
import { Colors } from 'constants/Styles';

import { FormsContext } from './Context';

class VersionForm extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    show: PropTypes.bool,
    version: PropTypes.object
  }

  constructor (props) {
    super(props);

    const form = props.form || {};
    const version = props.version || {
      name: form.name || '',
      number: form.number || '',
      description: form.description || '',
      states: form.states || [],
      form_tag_ids: form.form_tag_ids || [],
      product_group_ids: form.product_group_ids || [],
      file: form.pdf_file,
      new_file: null,
      publish_date: Moment().format()
    };

    this.state = Object.assign({}, version, {
      errors: [],
      show_file_button: false,
      saving: false
    });

    this.newFileInput = React.createRef();
  }

  _handleInputChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  }

  _handleChecklistChange = (name, selected) => {
    this.setState({
      [name]: selected
    });
  }

  _handleDateChange = (moment_date) => {
    this.setState({
      publish_date: moment_date.format()
    });
  }

  _handleFileChange = e => {
    this.setState({
      new_file: e.target.files[0]
    });
  }

  _changeFile = () => {
    this.newFileInput.current.click();
  }

  _toggleFileButton = () => {
    this.setState({ show_file_button: !this.state.show_file_button });
  }

  _viewFile = () => {
    if (this.state.file && this.state.file.url) {
      window.open(this.state.file.url);
    }
  }

  _save = () => {
    const { form = {}, version = {} } = this.props;
    const { name, number, description, states, form_tag_ids, product_group_ids, publish_date, new_file, file } = this.state;
    const errors = [];

    if (!name || name.length < 3) {
      errors.push('name');
    }

    if (!number || number.length < 3) {
      errors.push('number');
    }

    if (!file && !new_file) {
      errors.push('file');
    }

    if (errors.length) {
      this.setState({ errors });
    } else if (form.id) {
      const params = {
        name,
        number,
        description,
        states,
        publish_date,
        form_tag_ids,
        product_group_ids,
        form_version_id: version ? version.id : null
      };

      if (new_file) {
        params.file = new_file;
      }

      this.setState({ saving: true });

      Api.saveForm(form.id, params)
        .then(response => {
          this.setState({ saving: false });
          this.props.onSave(response.data);
        });
    } else {
      this.setState({ saving: true });

      Api.createForm({
        name,
        number,
        description,
        states,
        publish_date,
        form_tag_ids,
        product_group_ids,
        file: new_file
      })
        .then(response => {
          this.setState({ saving: false });
          this.props.onSave(response.data);
        });
    }
  }

  _delete = () => {
    Api.saveForm(this.props.form && this.props.form.id, {
      form_version_id: this.props.version.id,
      _destroy: true
    })
      .then(response => {
        this.props.onSave(response.data);
      });
  }

  render () {
    const styles = this.styles();
    const version_publish_date = Moment(this.props.version && this.props.version.publish_date);
    const scheduled = version_publish_date.isSameOrAfter(Moment().endOf('day'));
    const read_only = (this.props.version && this.props.version.id) && !scheduled;

    return (
      <Drawer
        footer={read_only ? (
          <div style={styles.published_footer}>
            Published: {version_publish_date.format('MMM Do, YYYY')}
          </div>
        ) : (
          <React.Fragment>
            <div style={styles.footer_btns}>
              <DatePicker
                borderRadius='20px 0px 0px 20px'
                disablePast={true}
                onChange={this._handleDateChange}
                padding='10px 25px 10px 15px'
                showTodayButton={true}
                style={styles.publish_date}
                value={this.state.publish_date}
              />
              <Button onClick={this._save} style={styles.button}>
                {scheduled ? 'Schedule' : 'Publish'}
              </Button>
            </div>
            {scheduled && this.props.form && this.props.form.id ? (
              <div
                onClick={this._delete}
                style={Object.assign({}, styles.link, { color: Colors.RED.hex })}
              >
                Delete
              </div>
            ) : null}
          </React.Fragment>
        )}
        onClose={this.props.onClose}
        show={this.props.show}
        title={this.props.form && this.props.form ? 'Edit Form' : 'New Form'}
      >
        {this.state.saving ? <div style={styles.loader}><Loader /></div> : null}
        <Field label='PDF File'>
          <input
            onChange={this._handleFileChange}
            ref={this.newFileInput}
            style={{ display: 'none' }}
            type='file'
          />
          <div
            onMouseEnter={this._toggleFileButton}
            onMouseLeave={this._toggleFileButton}
            style={Object.assign({}, styles.file_preview, this.state.errors.includes('file') && styles.file_error)}
          >
            {this.state.new_file ? (
              <div>
                <i className='mdi mdi-check-circle' style={{ fontSize: 100, color: Colors.GREEN.hex }} />
                <p>{this.state.new_file.name}</p>
              </div>
            ) : (
              <React.Fragment>
                {this.state.file && this.state.file.web_thumb.url ? (
                  <img onClick={this._viewFile} src={this.state.file.web_thumb.url} />
                ) : (
                  <i className='mdi mdi-file-question' style={{ fontSize: 100, color: '#e5e5e5' }} />
                )}
              </React.Fragment>
            )}

            {this.state.show_file_button && !read_only ? (
              <div style={styles.file_button}>
                <Button color='GREEN' onClick={this._changeFile}>Select File</Button>
                {this.state.new_file ? (
                  <div
                    onClick={() => {
                      this.setState({ new_file: null });
                    }}
                    style={styles.link}
                  >
                    Revert
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </Field>
        <Field label='Name'>
          <Input
            invalid={this.state.errors.includes('name')}
            onChange={this._handleInputChange.bind(null, 'name')}
            readOnly={read_only}
            required={true}
            value={this.state.name}
          />
        </Field>
        <Field label='Number'>
          <Input
            invalid={this.state.errors.includes('number')}
            onChange={this._handleInputChange.bind(null, 'number')}
            readOnly={read_only}
            required={true}
            value={this.state.number}
          />
        </Field>
        <Field label='Decription'>
          <Input
            onChange={this._handleInputChange.bind(null, 'description')}
            readOnly={read_only}
            required={true}
            type='textarea'
            value={this.state.description}
          />
        </Field>
        <Field label='Product Groups'>
          <Checklist
            onChange={this._handleChecklistChange.bind(null, 'product_group_ids')}
            options={this.context.product_groups.map(pg => ({ value: pg.id, label: pg.group_name }))}
            readOnly={read_only}
            selected={this.state.product_group_ids}
          />
        </Field>
        <Field label='Tags'>
          <Checklist
            onChange={this._handleChecklistChange.bind(null, 'form_tag_ids')}
            options={this.context.form_tags.map(ft => ({ value: ft.id, label: ft.name }))}
            readOnly={read_only}
            selected={this.state.form_tag_ids}
          />
        </Field>
        <Field label='States'>
          <Checklist
            onChange={this._handleChecklistChange.bind(null, 'states')}
            options={STATES}
            readOnly={read_only}
            selected={this.state.states}
            showSelectAll={true}
          />
        </Field>
      </Drawer>
    );
  }

  styles = () => {
    return {
      footer_btns: {
        display: 'flex'
      },
      published_footer: {
        background: '#444',
        color: '#fff',
        padding: 20,
        margin: '-20px -30px',
        fontWeight: 600,
        fontSize: 14,
        textAlign: 'center'
      },
      publish_date: {
        width: '100%',
        marginRight: -15
      },
      button: {
        position: 'relative'
      },
      file_preview: {
        position: 'relative',
        cursor: 'pointer',
        background: '#f5f5f5',
        borderRadius: 4,
        overflow: 'hidden',
        minHeight: 450,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      },
      file_button: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      },
      file_error: {
        border: `1px solid ${Colors.RED.hex}`
      },
      link: {
        color: Colors.BLUE.hex,
        fontWeight: 600,
        marginTop: 20,
        cursor: 'pointer',
        textAlign: 'center'
      },
      loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(255,255,255,0.9)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1
      }
    };
  }
}

VersionForm.contextType = FormsContext;

export default VersionForm;
