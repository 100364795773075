import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from 'constants/Styles';

const Button = (props) => {
  const color = Colors[props.color];
  const styles = {
    btn: Object.assign({}, {
      display: props.fullWidth ? 'block' : 'inline-block',
      width: props.fullWidth ? '100%' : 'auto',
      textAlign: 'center',
      fontSize: props.small ? 12 : 14,
      fontWeight: 700,
      color: '#fff',
      height: props.small ? 24 : 40,
      lineHeight: props.small ? '24px' : '38px',
      padding: props.small ? '0px 15px' : '0px 20px',
      borderRadius: 8,
      cursor: props.disabled ? 'default' : 'pointer',
      backgroundColor: color ? color.hex : Colors.BLUE.hex,
      opacity: props.disabled ? 0.8 : 1,
      boxShadow: 'inset 0px -2px 0px rgba(0,0,0,0.3)'
    }, props.style)
  };

  return (
    <div onClick={props.onClick} style={styles.btn}>
      <div className='flex flex--align-center flex--justify-center'>
        {props.children}
      </div>
    </div>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(['BLUE', 'RED', 'GREEN', 'GRAY']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  small: PropTypes.bool
};

export default Button;

