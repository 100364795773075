import React from 'react';
import PropTypes from 'prop-types';
// import Moment from 'moment';

import Loader from 'shared/Loader';
import Table from 'shared/Table';

import { Colors } from 'constants/Styles';

import Api from 'utils/Api';

class List extends React.Component {
  static propTypes = {
    filters: PropTypes.object
  }

  constructor (props) {
    super(props);

    this.state = {
      vendors: [],
      loading: true
    };
  }

  componentDidMount () {
    Api.loadVendorRelationships(this.props.filters)
      .then(response => {
        // TODO: hookup pagination
        // const pagination = JSON.parse(response.headers['x-pagination']);

        this.setState({
          vendors: response.data,
          loading: false
        });
      });
  }

  render () {
    const styles = this.styles();

    return (
      <React.Fragment>
        <Table
          columns={[
            {
              id: 'name',
              label: 'Name',
              is_sortable: true,
              render: c => {
                return (
                  <React.Fragment>
                    <div style={{ fontSize: 16, fontWeight: 700, fontFamily: 'Quicksand', color: Colors.GRAY.hex, marginBottom: 10 }}>
                      {c.name}
                    </div>
                    <div style={{ fontSize: 12, color: '#999' }}>
                      {c.business_type} - {c.url}
                    </div>
                  </React.Fragment>
                );
              },
              sort_by: c => c.name
            },
            {
              id: 'contact',
              label: 'Contact',
              is_sortable: true,
              render: c => {
                const contact = c.contacts[0];

                return contact ? `${contact.first_name} ${contact.last_name}` : 'N/A';
              }
            },
            {
              id: 'is_deactivated',
              label: 'Status',
              is_sortable: true,
              render: c => {
                return (
                  <div style={Object.assign({}, styles.pill, { background: c.is_deactivated ? Colors.RED.hex : Colors.GREEN.hex })}>
                    {c.is_deactivated ? 'Off' : 'On'}
                  </div>
                );
              }
            }
          ]}
          data={this.state.vendors}
          defaultSortColumn={{ id: 'name' }}
          onRowClick={v => {
            this.props.history.push(`/partners/details/${v.id}`);
          }}
        />
        {this.state.loading ? <Loader /> : null}
      </React.Fragment>
    );
  }

  styles = () => {
    return {
      pill: {
        display: 'inline-block',
        height: 22,
        lineHeight: '22px',
        padding: '0px 12px',
        fontSize: 10,
        fontWeight: 700,
        color: '#fff',
        letterSpacing: 1,
        textTransform: 'uppercase',
        backgroundColor: '#444',
        borderRadius: 22
      }
    };
  }
}

export default List;
