import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from 'constants/Styles';

const Toggle = props => {
  const styles = {
    toggle: {
      height: 24,
      width: 40,
      borderRadius: 12,
      padding: 2,
      background: props.active ? Colors.GREEN.hex : Colors.RED.hex,
      cursor: 'pointer',
      position: 'relative',
      transition: 'all .1s'
    },
    toggleHandle: {
      display: 'inline-block',
      width: 20,
      height: 20,
      borderRadius: '100%',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all .1s',
      position: 'absolute',
      left: props.active ? 2 : 18,
      top: 2
    },
    toggleHandleIcon: {
      color: props.active ? Colors.GREEN.hex : Colors.RED.hex
    }
  };

  return (
    <div onClick={props.onClick} style={styles.toggle}>
      <div style={styles.toggleHandle}>
        <i className={`mdi ${props.active ? 'mdi-check' : 'mdi-close'}`} style={styles.toggleHandleIcon} />
      </div>
    </div>
  );
};

Toggle.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default Toggle;

