import React from 'react';

import Button from 'shared/Button';
import Loader from 'shared/Loader';

import UserForm from './UserForm';

import { Colors } from 'constants/Styles';

import Api from 'utils/Api';

import { AuthContext } from 'src/AuthContext';

class Users extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      users: [],
      loading: true,
      user: false,
      showConfirm: false,
      showUserForm: false
    };
  }

  componentDidMount () {
    this._loadUsers();
  }

  _loadUsers = () => {
    Api.loadCarrierAdmins()
      .then(response => {
        this.setState({
          users: response.data,
          loading: false
        });
      });
  }

  _showUserForm = user => {
    this.setState({
      user,
      showUserForm: true
    });
  }

  _dismissUserForm = () => {
    this.setState({
      showUserForm: false
    });
  }

  _save = () => {
    this._loadUsers();
    this._dismissUserForm();
  }

  _delete = user => {
    this.setState({
      user,
      showConfirm: true
    });
  }

  _dismissDelete = () => {
    this.setState({
      user: false,
      showConfirm: false
    });
  }

  _confirmDelete = () => {
    Api.deleteCarrierAdmin(this.state.user.id)
      .then(response => {
        const users = this.state.users.filter(user => user.id !== response.data.id);

        this.setState({
          users,
          user: false,
          showConfirm: false
        });
      });
  }

  render () {
    const styles = this.styles();
    const current_user = this.context.user;

    return this.state.loading ? <Loader /> : (
      <div style={styles.component}>
        {this.state.showConfirm ? (
          <div style={styles.confirm}>
            <div style={styles.confirmBody}>
              Are you sure you want to delete <span style={{ fontWeight: 700 }}>{this.state.user.first_name} {this.state.user.last_name}'s</span> account?

              <div style={styles.confirmButtons}>
                <Button onClick={this._dismissDelete}>Nevermind</Button>
                <Button color='RED' onClick={this._confirmDelete} style={{ marginLeft: 6 }}>Yes, Delete Them</Button>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.showUserForm ? (
          <UserForm
            onClose={this._dismissUserForm}
            onSave={this._save}
            show={true}
            user={this.state.user}
          />
        ) : null}

        {this.state.users.map(user => {
          const is_current_user = current_user.id === user.id;

          return (
            <div key={user.id} style={styles.user}>
              <div style={styles.profile}>
                <div style={styles.name}>
                  {user.first_name} {user.last_name}
                  {is_current_user ? <span style={{ fontSize: 11, color: '#999', marginLeft: 12, letterSpacing: 1 }}>YOU</span> : null}
                </div>
                <a href={user.email} style={styles.email}>{user.email}</a>
              </div>
              {is_current_user ? null : (
                <React.Fragment>
                  <i className='mdi mdi-trash-can' onClick={this._delete.bind(null, user)} style={styles.delete} />
                  <i className='mdi mdi-pencil' onClick={this._showUserForm.bind(null, user)} style={styles.edit} />
                </React.Fragment>
              )}
            </div>
          );
        })}

        <Button onClick={this._showUserForm.bind(null, null)} style={{ marginTop: 20 }}>
          Add User <i className='mdi mdi-plus-circle-outline' style={{ fontSize: 18, marginLeft: 4 }} />
        </Button>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        padding: '40px 20px'
      },
      user: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 40
      },
      profile: {
        width: 300,
        flexShrink: 0
      },
      name: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 8,
        display: 'flex',
        alignItems: 'center'
      },
      email: {
        color: Colors.BLUE.hex,
        fontSize: 13,
        textDecoration: 'none'
      },
      permissions: {
        flex: 1
      },
      delete: {
        width: 40,
        height: 40,
        borderRadius: 40,
        backgroundColor: `rgba(${Colors.RED.rgb}, 0.2)`,
        lineHeight: '40px',
        textAlign: 'center',
        color: Colors.RED.hex,
        cursor: 'pointer',
        fontSize: 20,
        flexShrink: 0,
        marginLeft: 20
      },
      edit: {
        width: 40,
        height: 40,
        borderRadius: 40,
        backgroundColor: `rgba(${Colors.BLUE.rgb}, 0.2)`,
        lineHeight: '40px',
        textAlign: 'center',
        color: Colors.BLUE.hex,
        cursor: 'pointer',
        fontSize: 20,
        flexShrink: 0,
        marginLeft: 10
      },
      confirm: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(0,0,0,0.6)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      },
      confirmBody: {
        background: '#fff',
        padding: 40,
        width: 320,
        borderRadius: 8,
        fontSize: 16,
        lineHeight: '1.4em',
        marginBottom: 100
      },
      confirmButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20
      }
    };
  }
}

Users.contextType = AuthContext;

export default Users;
