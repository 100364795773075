import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'bitts';

const SelectField = (props) => {
  const styles = {
    select: Object.assign({}, {
      marginTop: 6,
      width: '100%',
      WebkitAppearance: 'none',
      boxShadow: 'none',
      outline: 'none',
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '40px',
      height: 40,
      padding: '2px 0px',
      background: '#fff'
    }, props.style)
  };

  return (
    <Select
      invalid={props.invalid}
      onChange={props.onChange}
      onClick={props.onClick}
      optionGroups={props.optionGroups}
      options={props.options}
      placeholder={props.placeholder}
      required={props.required}
      style={styles.select}
      value={props.value}
    />
  );
};

SelectField.propTypes = {
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  optionGroups: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })).isRequired
  })),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.node
};

export default SelectField;

