import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Drawer from 'shared/Drawer';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Select from 'shared/Select';
import PowerSelect from 'shared/PowerSelect';

import { PRODUCT_TYPE_DETAILS_BY_PRODUCT_TYPE } from 'constants/IXN';
import { Colors } from 'constants/Styles';

import Api from 'utils/Api';

class ProductForm extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
    product: PropTypes.object,
    show: PropTypes.bool
  }

  constructor (props) {
    super(props);

    const product = props.product || {};

    this.state = {
      product_name: product.product_name || '',
      product_group_id: product.product_group_id,
      product_type: product.product_type,
      product_type_detail: product.product_type_detail,
      selected_product_features: [],
      agent_message: product.agent_message || '',

      show_delete_warning: false,
      product_groups: [],
      product_features: [],
      errors: []
    };
  }

  componentDidMount () {
    Api.loadProductGroups()
      .then(response => {
        this.setState({
          product_groups: response.data.reverse()
        });
      });

    Api.loadProductFeatures()
      .then(response => {
        const product = this.props.product || {};

        this.setState({
          product_features: response.data,
          selected_product_features: product.product_feature_ids ? product.product_feature_ids.map(p => {
            const product_feature = response.data.find(pf => pf.id === p);

            return { value: p, label: product_feature.name };
          }) : []
        });
      });
  }

  _handleInputChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  }

  _handlePowerSelectChange = (name, values) => {
    this.setState({
      [name]: values || []
    });
  }

  _handleChecklistsChange = (name, selected) => {
    this.setState({
      [name]: selected
    });
  }

  _toggleDeleteWarning = () => {
    this.setState({
      show_delete_warning: !this.state.show_delete_warning
    });
  }

  _confirmDelete = () => {
    Api.deleteProduct(this.props.product.id)
      .then(response => {
        this.props.onDelete(response.data);
      });
  }

  _save = () => {
    const { product_name, product_group_id, product_type, product_type_detail } = this.state;
    const errors = [];

    if (!product_name || product_name.length < 3) {
      errors.push('product_name');
    }

    if (!parseInt(product_group_id, 10)) {
      errors.push('product_group_id');
    }

    if (!product_type || !product_type.length) {
      errors.push('product_type');
    }

    if (!product_type_detail || !product_type_detail.length) {
      errors.push('product_type_detail');
    }

    if (errors.length) {
      this.setState({ errors });
    } else {
      const {
        product_name,
        product_type,
        product_type_detail,
        product_group_id,
        agent_message,
        selected_product_features
      } = this.state;
      const params = {
        product_name,
        product_type,
        product_type_detail,
        product_group_id,
        agent_message,
        product_feature_ids: selected_product_features.map(p => p.value)
      };

      if (this.props.product) {
        Api.saveProduct(this.props.product.id, params)
          .then(response => {
            this.props.onSave(response.data);
          });
      } else {
        Api.createProduct(params)
          .then(response => {
            this.props.onSave(response.data);
          });
      }
    }
  }

  _renderTitle = () => {
    if (this.state.show_delete_warning) {
      return 'Caution!';
    } else {
      return this.props.product ? 'Edit Product' : 'New Product';
    }
  }

  _renderFooter = () => {
    if (this.state.show_delete_warning) {
      return (
        <React.Fragment>
          <Button color='RED' fullWidth={true} onClick={this._confirmDelete}>Confirm Deletion</Button>
          <div onClick={this._toggleDeleteWarning} style={{ color: Colors.BLUE.hex, fontWeight: 600, cursor: 'pointer', marginTop: 15, textAlign: 'center' }}>Nevermind, I made a mistake</div>
        </React.Fragment>
      );
    } else {
      return this.props.product ? (
        <React.Fragment>
          <Button fullWidth={true} onClick={this._save}>Save</Button>
          <div
            onClick={this._toggleDeleteWarning}
            style={{ color: Colors.RED.hex, fontWeight: 600, cursor: 'pointer', marginTop: 15, textAlign: 'center' }}
          >
            Delete
          </div>
        </React.Fragment>
      ) : (
        <Button fullWidth={true} onClick={this._save}>Create</Button>
      );
    }
  }

  render () {
    return (
      <Drawer
        footer={this._renderFooter()}
        onClose={this.props.onClose}
        show={this.props.show}
        title={this._renderTitle()}
      >
        {this.state.show_delete_warning ? (
          <React.Fragment>
            <p>Deleting this product will delete it's quote and form data! This means, they will no longer be available to partners. Proceed with caution.</p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Field label='Name'>
              <Input
                invalid={this.state.errors.includes('product_name')}
                onChange={this._handleInputChange.bind(null, 'product_name')}
                required={true}
                value={this.state.product_name}
              />
            </Field>

            <Field label='Product Group'>
              <Select
                invalid={this.state.errors.includes('product_group_id')}
                onChange={this._handleInputChange.bind(null, 'product_group_id')}
                options={[{ value: '', label: '-- Select One --' }].concat(this.state.product_groups.map(pg => ({ value: pg.id, label: pg.group_name })))}
                required={true}
                value={this.state.product_group_id}
              />
            </Field>

            <Field label='Type'>
              <Select
                invalid={this.state.errors.includes('product_type')}
                onChange={this._handleInputChange.bind(null, 'product_type')}
                options={[{ value: '', label: '-- Select One --' }].concat(Object.keys(PRODUCT_TYPE_DETAILS_BY_PRODUCT_TYPE).map(value => ({ value })))}
                required={true}
                value={this.state.product_type}
              />
            </Field>

            {this.state.product_type ? (
              <Field label='Detail'>
                <Select
                  invalid={this.state.errors.includes('product_type_detail')}
                  onChange={this._handleInputChange.bind(null, 'product_type_detail')}
                  options={[{ value: '', label: '-- Select One --' }].concat(PRODUCT_TYPE_DETAILS_BY_PRODUCT_TYPE[this.state.product_type].map(value => ({ value })))}
                  required={true}
                  value={this.state.product_type_detail}
                />
              </Field>
            ) : null}

            <Field label='Features'>
              <PowerSelect
                isMulti={true}
                noOptionsMessage='Start typing...'
                onChange={this._handlePowerSelectChange.bind(null, 'selected_product_features')}
                options={this.state.product_features.map(pf => ({ value: pf.id, label: pf.name }))}
                style={{ marginTop: 10 }}
                value={this.state.selected_product_features}
              />
            </Field>

            <Field label='Notes'>
              <Input
                invalid={this.state.errors.includes('agent_message')}
                onChange={this._handleInputChange.bind(null, 'agent_message')}
                style={{ height: 200 }}
                type='textarea'
                value={this.state.agent_message}
              />
            </Field>
          </React.Fragment>
        )}
      </Drawer>
    );
  }
}

export default ProductForm;
