import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { Colors } from 'constants/Styles';

const DatePicker = ({
  borderRadius,
  disablePast,
  onChange,
  padding,
  readOnly,
  showTodayButton,
  style,
  value
}) => {
  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: { main: Colors.GRAY.hex },
      secondary: { main: Colors.BLUE.hex }
    },
    overrides: {
      MuiFormControl: {
        root: {
          width: '100%'
        }
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: borderRadius || 4
        },
        input: {
          padding: padding || 10,
          height: 20,
          lineHeight: '20px'
        },
        notchedOutline: {
          borderColor: '#e5e5e5'
        }
      }
    }
  });

  return (
    <div style={style}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiDatePicker
            autoOk={true}
            disablePast={disablePast}
            format='MMM Do, YYYY'
            inputVariant='outlined'
            onChange={onChange}
            readOnly={readOnly}
            showTodayButton={showTodayButton}
            value={value}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};

DatePicker.propTypes = {
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disablePast: PropTypes.bool,
  onChange: PropTypes.func,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  showTodayButton: PropTypes.bool,
  value: PropTypes.string
};

export default DatePicker;

